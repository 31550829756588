export const SET_DEVICE_TYPE = 'SET_DEVICE_TYPE'
export const SET_CONTACT_US = 'SET_CONTACT_US'

export interface Session {
  isMobile: boolean
  showContactUs: boolean
}

export interface SetDeviceType {
  type: typeof SET_DEVICE_TYPE
  payload: {
    isMobile: boolean
  }
}
export interface SetContactUs {
  type: typeof SET_CONTACT_US
  payload: {
    showContactUs: boolean
  }
}

export type SessionActionTypes = SetDeviceType | SetContactUs

export interface SessionState extends Session {}

import { fork, takeLatest, call, put } from 'redux-saga/effects'

import {
  LOAD_PAYOUTS_HEADER_REQUEST,
  LOAD_PAYOUTS_TABLE_REQUEST,
  LoadPayoutsHeaderRequest,
  LoadPayoutsTableRequest,
  Payout
} from './types'
import { check } from '../users/utils'
import { API, graphqlOperation } from 'aws-amplify'

import {
  getLastPayout,
  getNextPayout,
  getPayouts
} from '../../../graphql/queries'
import {
  loadPayoutsHeaderFailure,
  loadPayoutsHeaderSuccess,
  loadPayoutsTableFailure,
  loadPayoutsTableSuccess
} from './actions'

function* fetchPayoutsHeader(action: LoadPayoutsHeaderRequest) {
  try {
    const getNextPayoutResult = yield call(
      [API, 'graphql'],
      graphqlOperation(getNextPayout, { stripeUserId: action.payload.stripeId })
    )
    const getLastPayoutResult = yield call(
      [API, 'graphql'],
      graphqlOperation(getLastPayout, { stripeUserId: action.payload.stripeId })
    )
    const nextPayout = check(getNextPayoutResult, 'getNextPayout')
    const lastPayout = check(getLastPayoutResult, 'getLastPayout')

    yield put(
      loadPayoutsHeaderSuccess({
        nextPayout: 'id' in nextPayout.payout ? nextPayout.payout : null,
        lastPayout: 'id' in lastPayout.payout ? lastPayout.payout : null
      })
    )
  } catch (error) {
    yield put(loadPayoutsHeaderFailure({ error: error.message }))
  }
}

function* watchPayoutsHeader() {
  yield takeLatest(LOAD_PAYOUTS_HEADER_REQUEST, fetchPayoutsHeader)
}

function* fetchPayoutsTable(action: LoadPayoutsTableRequest) {
  try {
    const { stripeId, offset, limit } = action.payload
    const result = yield call(
      [API, 'graphql'],
      graphqlOperation(getPayouts, { stripeUserId: stripeId, offset, limit })
    )
    const payouts: {
      statusCode: number
      body: { hasMore: boolean; payoutsList: Array<Payout> }
    } = check(result, 'getPayouts')
    yield put(
      loadPayoutsTableSuccess({
        rows: payouts.body.payoutsList.map(payout => ({
          bank: payout.bank,
          status: payout.status,
          id: payout.id,
          amount: payout.amount,
          created: payout.created,
          arrivalDate: payout.arrival_date
        })),
        hasMore: payouts.body.hasMore,
        append: !!offset
      })
    )
  } catch (error) {
    yield put(loadPayoutsTableFailure({ error: error.message }))
  }
}

function* watchPayoutsTable() {
  yield takeLatest(LOAD_PAYOUTS_TABLE_REQUEST, fetchPayoutsTable)
}

const payoutsSagas = [fork(watchPayoutsHeader), fork(watchPayoutsTable)]
export default payoutsSagas

export const CREATE_REFUND_REQUEST = 'CREATE_REFUND_REQUEST'
export const CREATE_REFUND_SUCCESS = 'CREATE_REFUND_SUCCESS'
export const CREATE_REFUND_FAILURE = 'CREATE_REFUND_FAILURE'
export const RESET_REFUND = 'RESET_REFUND'

export interface CreateRefundRequest {
  type: typeof CREATE_REFUND_REQUEST
  payload: {
    stripeId: string
    transferId: string
    transferMoonaId: string
    transferShippingId: string
    amount: number
    payback: number
    customer: string
    currency: string
    fromDashboard: boolean
  }
}
export interface CreateRefundSuccess {
  type: typeof CREATE_REFUND_SUCCESS
  payload: {
    amount: number
    payback: number
    isFullRefund: boolean
    currency: string
  }
}
export interface CreateRefundFailure {
  type: typeof CREATE_REFUND_FAILURE
  payload: {
    error: string
    amount: number
    payback: number
    currency: string
  }
}
export interface ResetRefund {
  type: typeof RESET_REFUND
}

export interface RefundState {
  loading: boolean
  success: boolean
  failure: boolean
  error: string
  amount: number
  isFullRefund: boolean
  payback: number
  customer: string
  currency: string
}

export type RefundActionTypes =
  | CreateRefundRequest
  | CreateRefundSuccess
  | CreateRefundFailure
  | ResetRefund

import React, { useEffect } from 'react'
import { Route, RouteProps } from 'react-router-dom'

import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { Metadata } from 'common/components/SEO/Metadata'
import { useDeviceType } from 'common/style/queries/useDeviceType'
import { useScrollToTop } from 'common/hooks/useScollToTop'
import { useDispatch } from 'react-redux'
import { setDeviceType } from '../../../state/features/session/actions'
export interface ComponentProps extends RouteProps {}
const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY || '')
interface Props extends RouteProps {
  component: React.FC<ComponentProps>
}
const Layout: React.FC<Props> = ({
  component: Component,
  location,
  ...rest
}) => {
  const dispatch = useDispatch()
  const stripeOptions = {
    locale: 'en' as const
  }
  const deviceType = useDeviceType()
  useEffect(() => {
    dispatch(setDeviceType(deviceType))
  }, [dispatch, deviceType])

  return (
    <Route
      {...rest}
      location={location}
      render={matchProps => (
        <div>
          <RunHook />
          <Metadata location={location?.pathname} />
          <Elements stripe={stripePromise} options={stripeOptions}>
            <Component {...matchProps} location={location} />
          </Elements>
        </div>
      )}
    />
  )
}

const RunHook = () => {
  useScrollToTop()

  return null
}
export default Layout

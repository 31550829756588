import React from 'react'
import styled, { css } from 'styled-components'
import { tablet } from '../queries/media-queries'

export const baseTitleCss = css`
  display: flex;
  align-items: center;
  color: var(--color-blackmain-moona-02);
`

export const H1 = styled.h1`
  ${baseTitleCss}
  font-weight: 500;
  font-size: 2.8rem;
  line-height: 4.2rem;
  color: var(--color-black-moona-02);
`

export const H2 = styled.h2`
  ${baseTitleCss}
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.4rem;
  color: var(--color-black-moona-02);
`
export const H3 = styled.h3`
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.4rem;
  ${baseTitleCss}
`
export const H4 = styled.h4`
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.1rem;
  ${baseTitleCss}
`

export const baseFooterTextCss = css`
  font-size: 1.2rem;
  line-height: 2.1rem;
  color: var(--color-grey-main);
`

export const Body = styled.p`
  color: var(--color-black-moona);
  font-size: 1.2rem;
  line-height: 2.4rem;
  @media ${tablet} {
    font-size: 1.6rem;
  }
`
export const BodyDiv = styled.div`
  color: var(--color-black-moona);
  font-size: 1.2rem;
  line-height: 2.4rem;
  @media ${tablet} {
    font-size: 1.6rem;
  }
`

interface TitleProps {
  title: string
  className?: string
}

export const Title: React.FC<TitleProps> = ({ title, className }) => {
  return (
    <H1 className={className}>
      {title.split('  ').map((token, i) => (
        <span key={i}>{token}</span>
      ))}
    </H1>
  )
}

interface MdProps {
  content: string
  className?: string
}

export const MdBody: React.FC<MdProps> = ({ content, className }) => {
  return (
    <Body className={className}>
      {content.split('  ').map((token, i) => (
        <span key={i}>{token}</span>
      ))}
    </Body>
  )
}

export const MdH1: React.FC<MdProps> = ({ content, className }) => {
  return (
    <H1 className={className}>
      {content.split('  ').map((token, i) => (
        <span key={i}>{token}</span>
      ))}
    </H1>
  )
}
export const MdH3: React.FC<MdProps> = ({ content, className }) => {
  return (
    <H3 className={className}>
      {content.split('  ').map((token, i) => (
        <span key={i}>{token}</span>
      ))}
    </H3>
  )
}
export const MdTitle2: React.FC<MdProps> = ({ content, className }) => {
  return (
    <H2 className={className}>
      {content.split('  ').map((token, i) => (
        <span key={i}>{token}</span>
      ))}
    </H2>
  )
}

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`
export const ListItem = styled.li``

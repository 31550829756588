const setViewHeight = () =>
  document.documentElement.style.setProperty(
    '--vh',
    `${window.innerHeight * 0.01}px`
  )

export const syncViewHeightCssVariable = () => {
  setViewHeight()
  window.addEventListener('resize', setViewHeight)
}

import React from 'react'
import { BrowserRouter, Switch, Redirect } from 'react-router-dom'
import ProtectedRoutes from './common/components/ProtectedRoutes/ProtectedRoutes'
import AuthenticationRoutes from './common/components/AuthenticationRoutes/AuthenticationRoutes'
import { LoginPage } from './pages/Login'
import { RegisterPage } from './pages/Register'
import { TermsPage } from './pages/Terms'
import { ForgotPasswordPage } from './pages/ForgotPassword'
import { DashboardPage } from './pages/Dashboard'
import { ResetPasswordPage } from './pages/ResetPassword'

import { useTranslation } from 'react-i18next'
import Layout from './common/components/Layout'
import { PayoutsPage } from 'pages/Payouts'

const Routes: React.FC = () => {
  const [t] = useTranslation('app')
  return (
    <BrowserRouter>
      <Switch>
        <AuthenticationRoutes
          exact
          path={t('routes.login')}
          component={LoginPage}
        />

        <Layout exact path={t('routes.terms')} component={TermsPage} />

        <ProtectedRoutes
          exact
          path={t('routes.dashboard')}
          component={DashboardPage}
        />
        <ProtectedRoutes
          exact
          path={t('routes.payouts')}
          component={PayoutsPage}
        />
        <Layout
          exact
          path={t('routes.forgotpassword')}
          component={ForgotPasswordPage}
        />
        <Layout
          exact
          path={t('routes.resetpassword')}
          component={ResetPasswordPage}
        />
        <Layout exact path={t('routes.register')} component={RegisterPage} />

        <Redirect to={t('routes.dashboard')} />
      </Switch>
    </BrowserRouter>
  )
}

export default Routes

import i18n from 'i18next'
import LngDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import en from './en.json'
import fr from './fr.json'

const language =
  new URLSearchParams(window.location.search).get('language') || undefined

i18n
  .use(LngDetector)
  .use(initReactI18next)
  .init({
    resources: { en, fr },
    fallbackLng: 'en',
    lng: language,

    interpolation: {
      escapeValue: false
    }
  })

export { i18n }

import { call, put, takeEvery, fork } from 'redux-saga/effects'

import {
  LoadDataRequest,
  LOAD_DATA_REQUEST,
  ServerTransactionRow,
  TransactionRow
} from './types'
import { loadTableSuccess, loadTableFailure } from './actions'
import { API, graphqlOperation } from 'aws-amplify'
import { getTransactionsList } from 'graphql/queries'
import { check } from '../users/utils'
import { isNaN } from 'formik'

function* table(action: LoadDataRequest) {
  try {
    const { stripeId, offset, limit, email } = action.payload

    const tableResult = yield call(
      [API, 'graphql'],
      graphqlOperation(getTransactionsList, {
        stripeUserId: stripeId,
        offset,
        limit,
        email
      })
    )

    const tableData = check(tableResult, 'getTransactionsList')
    const { transactionsList, hasMore } = tableData.body as {
      transactionsList: ServerTransactionRow[]
      hasMore: boolean
    }
    const transactions: TransactionRow[] = transactionsList
      .map(
        ({
          orderId,
          customerEmail,
          amount: amountWithCentims,
          datePayment,
          currency,
          moonaFunding: moonaFundingWithCentims,
          transactions,
          type,
          reversed,
          isLastPartialRefund,
          amountReversed
        }) => {
          const amount = +amountWithCentims
          const moonaFunding = moonaFundingWithCentims
            ? +moonaFundingWithCentims
            : 0
          const total = (() => {
            if (type === 'refund') {
              if (!reversed) {
                // here, the transaction has not been fully refunded
                return amount
              }
              if (
                reversed &&
                amount !== amountReversed &&
                isLastPartialRefund
              ) {
                // here, it is a partial refund that complete a refund
                return amount + moonaFunding
              }
              if (reversed && amount !== amountReversed) {
                // here, the transaction has been partially refunded
                return amount
              }
              if (reversed && amount === amountReversed) {
                // here, the transaction has been fully refunded
                return amount + moonaFunding
              }
            }
            return amount + moonaFunding
          })()

          const isRefund = type === 'refund'

          return {
            id: orderId || 'todo',
            customer: customerEmail,
            total: isRefund ? -total : total,
            amount: isRefund ? -amount : amount,
            payback: isRefund ? -moonaFunding : moonaFunding,
            date: datePayment,
            currency,
            isLastPartialRefund,
            refund: (() => {
              if (type === 'moona-discount' && reversed ? 'Refunded' : '') {
                return 'Refunded'
              }
              if (type === 'refund') return 'Refunded'
              return ''
            })() as TransactionRow['refund'],
            transactions: transactions,
            amountReversed: amountReversed,
            reversed,
            type
          }
        }
      )
      .filter(transaction => {
        if (
          !transaction.type ||
          !transaction.date ||
          isNaN(transaction.amount)
        ) {
          return false
        }
        return true
      })

    yield put(
      loadTableSuccess({
        data: transactions,
        hasMore,
        append: (() => {
          if (offset) return true
          if (email) return false
          return false
        })()
      })
    )
  } catch (error) {
    let msg = ''
    switch (error.code) {
      case 'ERROR-GET-TRANSACTIONS-LIST-003':
        msg = 'ERROR-GET-TRANSACTIONS-LIST-003'; //'Customer doesn\'t exist'
        break
      case 'ERROR-GET-TRANSACTIONS-LIST-004':
        msg = 'ERROR-GET-TRANSACTIONS-LIST-004'; //'No payments for this customer'
        break
      case 'ERROR-GET-TRANSACTIONS-LIST-005':
        msg = 'ERROR-GET-TRANSACTIONS-LIST-005'; //'No payments for this customer on your website'
        break  
      default:
        msg = 'error from stripe'
        break
    }

    yield put(loadTableFailure({ error: msg }))
  }
}

function* watchTable() {
  yield takeEvery(LOAD_DATA_REQUEST, table)
}

const tableSagas = [fork(watchTable)]
export default tableSagas

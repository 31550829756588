import { SET_DEVICE_TYPE, SET_CONTACT_US } from './types'

import { DeviceType } from 'common/style/queries/useDeviceType'
import { AppActions } from '../types'

export const setDeviceType = (deviceType: DeviceType): AppActions => {
  const isMobile = deviceType === DeviceType.mobile
  return {
    type: SET_DEVICE_TYPE,
    payload: {
      isMobile
    }
  }
}
export const setContactUs = (showModal: boolean): AppActions => {
  return {
    type: SET_CONTACT_US,
    payload: {
      showContactUs: showModal
    }
  }
}

import { API, graphqlOperation } from 'aws-amplify'

import { merchantsByEmail } from '../../../graphql/queries'

const STRIPE_ONBOARDING_URL =
  'https://connect.stripe.com/express/oauth/authorize'

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID || ''
const REDIRECT_URI = '/dashboard'

export const isRegisteredEmail = async email => {
  const data = await API.graphql(
    graphqlOperation(merchantsByEmail, { email: email.toLowerCase() })
  )

  return (
    data.data.merchantsByEmail.items &&
    data.data.merchantsByEmail.items.length > 0
  )
}

export const redirectToStripe = ({
  id,
  email,
  firstName,
  lastName,
  businessUrl,
  phone,
  businessDescription,
  companyType
}) => {
  let urlStripeOnboarding = STRIPE_ONBOARDING_URL

  const formattedPhone = phone.replace('+44', '')

  urlStripeOnboarding += '?client_id=' + CLIENT_ID
  urlStripeOnboarding +=
    '&redirect_uri=' + window.location.origin + REDIRECT_URI
  urlStripeOnboarding += '&state=' + id
  // urlStripeOnboarding += '&stripe_user[name]=' + 'coucou'
  urlStripeOnboarding += '&stripe_user[email]=' + email
  urlStripeOnboarding += '&stripe_user[first_name]=' + firstName
  urlStripeOnboarding += '&stripe_user[last_name]=' + lastName
  urlStripeOnboarding += '&stripe_user[url]=' + businessUrl // http(s) mandatory
  urlStripeOnboarding +=
    '&suggested_capabilities[]=card_payments&suggested_capabilities[]=transfers'

  urlStripeOnboarding += '&stripe_user[country]=gb' // + country // optional
  urlStripeOnboarding += '&stripe_user[phone_number]=' + formattedPhone // check format because must be 10 digits only
  urlStripeOnboarding += '&stripe_user[business_name]=' + businessDescription

  urlStripeOnboarding += '&stripe_user[business_type]=' + companyType // only support `individual` / `company`

  window.location.href = urlStripeOnboarding
}

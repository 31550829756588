import React from 'react'

import Layout from '../Layout'
import { useSelector } from 'react-redux'
import { AppState } from '../../../state/features/types'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'

interface Props {
  component: React.FC
  path: string
  exact?: boolean
}
const AuthenticationRoutes: React.FC<Props> = ({
  component: Component,
  path
}) => {
  const [t] = useTranslation('app')

  const { logged } = useSelector((state: AppState) => {
    return state.auth
  })

  if (logged) {
    return <Redirect to={t('routes.payment')} />
  }

  return <Layout exact path={path} component={Component} />
}

export default AuthenticationRoutes

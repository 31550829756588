import {
  CREATE_PAYOUT_REQUEST,
  CREATE_PAYOUT_ERROR,
  CREATE_PAYOUT_SUCCESS,
  LOAD_PAYOUT_REQUEST,
  LOAD_PAYOUT_FAILURE,
  LOAD_PAYOUT_SUCCESS,
  CREATE_PAYOUT_FAILURE,
  LOAD_PAYOUT_STATUS,
  SET_PAYOUT_STATUS,
  RESET_PAYOUT
} from './types'

import { AppActions } from '../types'

export const createPayoutRequest = (stripeId: string): AppActions => ({
  type: CREATE_PAYOUT_REQUEST,
  payload: { stripeId: stripeId }
})
export const createPayoutSuccess = (): AppActions => ({
  type: CREATE_PAYOUT_SUCCESS
})
export const createPayoutFailure = ({
  error
}: {
  error: string
}): AppActions => ({
  type: CREATE_PAYOUT_FAILURE,
  payload: {
    error
  }
})

export const loadPayoutRequest = (stripeId: string): AppActions => ({
  type: LOAD_PAYOUT_REQUEST,
  payload: { stripeId: stripeId }
})
export const loadPayoutSuccess = (amount: number): AppActions => ({
  type: LOAD_PAYOUT_SUCCESS,
  payload: {
    amount
  }
})
export const loadPayoutFailure = ({
  error
}: {
  error: string
}): AppActions => ({
  type: LOAD_PAYOUT_FAILURE,
  payload: {
    error
  }
})

export const loadPayoutStatus = (stripeId: string): AppActions => ({
  type: LOAD_PAYOUT_STATUS,
  payload: { stripeId: stripeId }
})

export const setPayoutStatus = (payoutsEnabled: boolean): AppActions => ({
  type: SET_PAYOUT_STATUS,
  payload: { payoutsEnabled }
})

export const createPayoutError = ({
  error
}: {
  error: string
}): AppActions => ({
  type: CREATE_PAYOUT_ERROR,
  payload: {
    error
  }
})
export const resetPayout = (): AppActions => ({
  type: RESET_PAYOUT
})

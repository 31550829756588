import {
  CLEAR_SEARCH_EMAIL,
  LOAD_DATA_ERROR,
  LOAD_DATA_FAILURE,
  LOAD_DATA_REQUEST,
  LOAD_DATA_SUCCESS,
  RESET_DATA,
  SET_SEARCH_EMAIL,
  TableActionTypes,
  TableState
} from './types'

export const initialTableState: TableState = {
  hasMore: false,
  pageIndex: 0,
  loading: false,
  success: false,
  failure: false,
  data: [],
  searchEmail: '',
  error: ''
}

export default function table(
  state = initialTableState,
  action: TableActionTypes
): TableState {
  switch (action.type) {
    case SET_SEARCH_EMAIL:
      return {
        ...state,
        searchEmail: action.payload.value
      }
    case CLEAR_SEARCH_EMAIL:
      return {
        ...state,
        searchEmail: ''
      }
    case LOAD_DATA_REQUEST:
      return {
        ...state,
        loading: true
      }
    case LOAD_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        failure: false,
        ...(action.payload.append
          ? {
              data: [...state.data, ...action.payload.data]
            }
          : { data: action.payload.data }),
        pageIndex: action.payload.append ? state.pageIndex + 1 : 0,
        hasMore: action.payload.hasMore,
        error: ''
      }
    case LOAD_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        failure: true,
        error: action.payload.error,
        hasMore: false
      }

    case LOAD_DATA_ERROR:
      return {
        ...state,
        error: action.payload.error
      }
    case RESET_DATA:
      return {
        ...initialTableState
      }

    default:
      return state
  }
}

import { combineReducers } from 'redux'
import UsersReducer from './users/reducers'
import AuthReducer from './auth/reducers'

import PayoutReducer from './payout/reducers'
import PayoutsReducer from './payouts/reducers'
import SessionReducer from './session/reducers'
import RefundReducer from './refund/reducers'
import TableReducer from './table/reducers'
import TransactionsReducer from './transactions/reducers'

import usersSagas from './users/sagas'
import authSagas from './auth/sagas'
import payoutSagas from './payout/sagas'
import payoutsSagas from './payouts/sagas'
import refundSagas from './refund/sagas'
import tableSagas from './table/sagas'
import transactionsSagas from './transactions/sagas'

import { all } from 'redux-saga/effects'

export const rootReducer = combineReducers({
  users: UsersReducer,
  auth: AuthReducer,
  payout: PayoutReducer,
  payouts: PayoutsReducer,
  session: SessionReducer,
  refund: RefundReducer,
  table: TableReducer,
  transactions: TransactionsReducer
})

export function* rootSaga() {
  yield all([
    ...usersSagas,
    ...authSagas,
    ...payoutSagas,
    ...payoutsSagas,
    ...refundSagas,
    ...tableSagas,
    ...transactionsSagas
  ])
}

import Modal from 'styled-react-modal'
import styled from 'styled-components'
import {
  Section,
  CenterContentContainer
} from 'common/style/components/Section'

import { Image } from 'common/style/components/image'
import { BodyDiv, MdH1 } from 'common/style/components/Typography'
import {
  PrimaryButton,
  CancelButton,
  SuccessButton,
  ErrorButton,
  ButtonLink,
  SubmittablePrimaryButton
} from 'common/style/components/buttons'

import { tablet } from 'common/style/queries/media-queries'

import { InlineLink } from 'common/style/components/links'

export const StyledModal = Modal.styled`
   width: 34.7rem;
      height: 36.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  position:relative;
  border: 1px solid #E8E9ED;
  box-shadow: 0px 20px 58px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  @media ${tablet} {
      width: 68rem;
      height: 47rem;
  }
`

export const ModalBackground = styled.div`
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 30;
  background: rgba(41, 43, 54, 0.8);
`
export const MoonaModalSection = styled(Section)`
  padding: 4.4rem 1.9rem;
  @media ${tablet} {
    padding: 6.8rem 6rem;
  }
`
export const MoonaModalContentContainer = styled(CenterContentContainer)``

export const MoonaModalBoxCTA = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  @media ${tablet} {
    flex-direction: row;
  }
`

export const MoonaModalSubmittableButton = styled(SubmittablePrimaryButton)<{
  onClick?: (e: MouseEvent) => void
}>`
  order: 0;
  width: 16rem;
  @media ${tablet} {
    order: 1;
  }
`

export const MoonaModalPrimaryBtn = styled(PrimaryButton)`
  order: 0;
  @media ${tablet} {
    order: 1;
    width: 16rem;
  }
`
export const MoonaModalSucessBtn = styled(SuccessButton)`
  order: 0;
  width: 13rem;
  @media ${tablet} {
    order: 1;
    width: 16rem;
  }
`
export const MoonaModalErrorBtn = styled(ErrorButton)`
  order: 0;
  width: 13rem;
  @media ${tablet} {
    order: 1;
    width: 16rem;
  }
`

export const MoonaModalCancelBtn = styled(CancelButton)<{
  event?: React.MouseEvent
}>`
  @media ${tablet} {
    order: 0;
    margin-right: 1.6rem;
    width: 16rem;
  }
`
export const MoonaModalInlineLink = styled(InlineLink)`
  width: 100%;
  order: 1;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: var(--color-black-moona);
  margin-left: 0;
`
export const MoonaModalInlineButton = styled(ButtonLink)<{
  event?: React.MouseEvent
}>`
  width: 100%;
  order: 1;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: var(--color-black-moona);
  margin-left: 0;
  margin-top: 1.1rem;
`
export const MoonaModalTitle = styled(MdH1)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
export const MoonaModalBody = styled(BodyDiv)`
  display: flex;

  flex-direction: row;
  justify-content: center;

  align-items: flex-start;
  & > div > span.black {
    color: var(--color-black-moona-02);
    margin-right: 0.5rem;
  }

  @media ${tablet} {
    & > div > span.black {
      color: var(--color-black-moona-02);
      margin-right: 0.5rem;
    }
    align-items: center;
  }
`
export const MoonaModalBodyDesktop = styled(MoonaModalBody)`
  display: none;
  @media ${tablet} {
    display: flex;
  }
`

export const MoonaModalConfirmation = styled(MoonaModalBody)`
  font-size: 1.6rem;
  line-height: 3.2rem;

  color: var(--color-grey-main);
  @media ${tablet} {
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.4rem;
    color: var(--color-black-moona-02);
  }
`

export const MoonaModalClose = styled(Image)`
  position: absolute;
  top: 2.5rem;
  right: 2.5rem;
  cursor: pointer;
  z-index: 10;
`
export const MoonaModalStatusIcon = styled(Image)`
  display: none;
  @media ${tablet} {
    display: block;
    margin-right: 0.8rem;
  }
`

export const MoonaModalBodyBox = styled.div`
  margin-top: 1.6rem;

  background: #ffffff;
  border: 1px solid var(--color-grey-light);
  box-sizing: border-box;
  border-radius: 12px;
  padding: 2.1rem 1rem 2.5rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: var(--color-grey-main);

  & > :not(:last-child) {
    margin-bottom: 1rem;
  }

  @media ${tablet} {
    margin-top: 4rem;
    padding: 2.8rem 2.4rem 2rem 2.4rem;
    & > :not(:last-child) {
      margin-bottom: 0.8rem;
    }
  }
`

export const MoonaModalFeedbackBodyBox = styled(MoonaModalBodyBox)`
  border: 1px solid var(--color-grey-light);
  padding: 2.1rem 1rem 1.8rem 1rem;
  @media ${tablet} {
    border: none;
    padding: 2.1rem 1rem 2.5rem 1rem;
    width: 32.8rem;
  }
`

export const MoonaModalLabel = styled.div`
  margin-top: 4rem;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.4rem;
  color: var(--color-black-moona-02);
`

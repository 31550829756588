import { call, put, takeEvery, fork } from 'redux-saga/effects'

import { LOAD_TRANSACTIONS_REQUEST, LoadTransactionsRequest } from './types'
import { createTransactionsSuccess, createTransactionsFailure } from './actions'
import { API, graphqlOperation } from 'aws-amplify'
import { getTotalTransactions } from 'graphql/queries'
import { check } from '../users/utils'

function* transactions(action: LoadTransactionsRequest) {
  if (!action.payload.stripeId) {
    return
  }
  try {
    const transactionsResult = yield call(
      [API, 'graphql'],
      graphqlOperation(getTotalTransactions, {
        stripeUserId: action.payload.stripeId
      })
    )

    const transactionsData = check(transactionsResult, 'getTotalTransactions')

    const { total, moonaFunding } = transactionsData.body
    yield put(
      createTransactionsSuccess({
        total: total / 100,
        moonaFunding: moonaFunding / 100
      })
    )
  } catch (error) {
    console.log('error while getting transactions', error.code, error.message)
    yield put(createTransactionsFailure({ error: 'error from stripe' }))
  }
}

function* watchTransactions() {
  yield takeEvery(LOAD_TRANSACTIONS_REQUEST, transactions)
}

const refundSagas = [fork(watchTransactions)]
export default refundSagas

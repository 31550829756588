import { call, put, takeEvery, fork } from 'redux-saga/effects'
import {
  loggedIn,
  loggableSuccess,
  loginFail,
  createForgotPasswordSuccess,
  createForgotPasswordFailure
} from './actions'
import {
  LOGIN,
  LOGOUT,
  LOGGABLE,
  LogginAction,
  FORGOT_PASSWORD,
  ForgotPasswordAction
} from './types'
import { loadUser, clearUser, fetchAccountLink } from '../users/actions'

import { API, Auth, graphqlOperation } from 'aws-amplify'
import { merchantsByEmail } from 'graphql/queries'
import { redirectToStripe } from '../users/api'
import { loadPayoutRequest, loadPayoutStatus } from '../payout/actions'
import { loadTableRequest } from '../table/actions'
import { createTransactionsRequest } from '../transactions/actions'
import { deleteState } from 'state/localStorage'
// import { now } from 'lodash'

// const TTL = 20 * 60 * 1000 //20 minutes in milliseconds

function* authenticate(action: LogginAction) {
  var { login, password } = action.payload

  login = login.toLowerCase()

  try {
    yield call([Auth, 'signIn'], {
      username: login,
      password: password
    })

    const merchantData = yield call(
      [API, 'graphql'],
      graphqlOperation(merchantsByEmail, { email: login })
    )

    if (
      merchantData.data.merchantsByEmail.items &&
      merchantData.data.merchantsByEmail.items.length > 0
    ) {
      const merchant = merchantData.data.merchantsByEmail.items[0]
      if (merchant.stripeId) {
        yield put(loadPayoutStatus(merchant.stripeId))

        yield put(
          loadUser({
            firstName: merchant.firstname,
            lastName: merchant.lastname,
            email: merchant.email,
            phone: merchant.phone,
            stripeId: merchant.stripeId,
            id: merchant.id,
            businessUrl: merchant.website,
            businessDescription: merchant.businessDescription,
            companyType: merchant.companyType,
            status: merchant.status
          })
        )

        const { stripeId } = merchant
        yield put(fetchAccountLink(stripeId))
        yield put(loadPayoutRequest(stripeId))
        yield put(loadTableRequest(stripeId))
        yield put(createTransactionsRequest(stripeId))

        // localStorage.setItem(
        //   'token',
        //   JSON.stringify({
        //     expiry: now() + TTL
        //   })
        // )

        yield put(loggedIn())
      } else {
        redirectToStripe({
          id: merchant.id,
          email: merchant.email,
          firstName: merchant.firstname,
          lastName: merchant.lastname,
          businessUrl: merchant.website,
          phone: merchant.phone,
          businessDescription: merchant.businessDescription,
          companyType: merchant.companyType
        })
      }
    } else {
      yield put(loginFail())
    }
  } catch (error) {
    yield put(loginFail())
  }
}

function* watchAuth() {
  yield takeEvery(LOGIN, authenticate)
}

function* logout() {
  deleteState()
  yield put(clearUser())
}

function* watchLogout() {
  yield takeEvery(LOGOUT, logout)
}

function* loggable() {
  yield put(loggableSuccess(true))
}

function* watchLoggable() {
  yield takeEvery(LOGGABLE, loggable)
}

function* forgotPasswordGen(action: ForgotPasswordAction) {
  try {
    yield call([Auth, 'forgotPassword'], action.payload.login, {
      group: 'merchant'
    })
    yield put(createForgotPasswordSuccess())
  } catch (error) {
    console.log(error)
    let msg = ''
    if (error.code === 'LimitExceededException') {
      msg = action.payload.messages.limitExceededException
      console.log(error)
    } else {
      msg = action.payload.messages.defaultException
      console.log('error forgot pwd in cognito', error)
    }
    yield put(createForgotPasswordFailure({ error: msg }))
  }
}

function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, forgotPasswordGen)
}

const authSagas = [
  fork(watchAuth),
  fork(watchLoggable),
  fork(watchLogout),
  fork(watchForgotPassword)
]
export default authSagas

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useLocation } from 'react-router-dom'
import Layout from '../Layout'
import qh from 'query-string'
import { AppState } from '../../../state/features/types'
import { loggedIn } from 'state/features/auth/actions'

import { finalizeRegistration } from 'state/features/users/actions'
interface Props {
  component: React.FC
  path: string
  exact?: boolean
}
const ProtectedRoutes: React.FC<Props> = ({ component: Component, path }) => {
  const auth = useSelector((state: AppState) => {
    return state.auth
  })

  const dispatch = useDispatch()

  const location = useLocation()

  const query = qh.parse(location.search)

  if (
    !auth.logged &&
    query.code &&
    query.state &&
    auth.finalizeRegistrationStatus !== 'done'
  ) {
    dispatch(loggedIn())
  }

  if (
    auth.logged &&
    query.code &&
    query.state &&
    auth.finalizeRegistrationStatus !== 'done'
  ) {
    const code = (query.code as string) || ''
    const state = (query.state as string) || ''

    dispatch(finalizeRegistration({ code, state }))
  }

  if (!auth.logged && auth.finalizeRegistrationStatus === 'done') {
    return <Redirect to="/login" />
  }

  if (!auth.logged && (!query.code || !query.state)) {
    return <Redirect to="/login" />
  }

  return <Layout exact path={path} component={Component} />
}

export default ProtectedRoutes

/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createMoonaUser = /* GraphQL */ `
  query CreateMoonaUser(
    $email: String!
    $firstname: String!
    $lastname: String!
    $phone: String
    $address: String
    $postcode: String
    $city: String
    $country: String
    $token: String
    $stripeOnly: Boolean
  ) {
    createMoonaUser(
      email: $email
      firstname: $firstname
      lastname: $lastname
      phone: $phone
      address: $address
      postcode: $postcode
      city: $city
      country: $country
      token: $token
      stripeOnly: $stripeOnly
    )
  }
`;
export const getPaymentIntent = /* GraphQL */ `
  query GetPaymentIntent(
    $userId: ID!
    $stripeMerchantId: ID!
    $price: Float!
    $currency: String
    $stripePaymentMethod: String
    $orderId: String
    $ecommerceSessionId: String
    $endTrial: Boolean
    $subscriptionWithoutTrialPeriod: Boolean
    $payload: String
  ) {
    getPaymentIntent(
      userId: $userId
      stripeMerchantId: $stripeMerchantId
      price: $price
      currency: $currency
      stripePaymentMethod: $stripePaymentMethod
      orderId: $orderId
      ecommerceSessionId: $ecommerceSessionId
      endTrial: $endTrial
      subscriptionWithoutTrialPeriod: $subscriptionWithoutTrialPeriod
      payload: $payload
    )
  }
`;
export const setUserFirstPassword = /* GraphQL */ `
  query SetUserFirstPassword($email: String!, $newPassword: String!) {
    setUserFirstPassword(email: $email, newPassword: $newPassword)
  }
`;
export const deleteFirstUser = /* GraphQL */ `
  query DeleteFirstUser($moonaId: String!) {
    deleteFirstUser(moonaId: $moonaId)
  }
`;
export const getStripeCards = /* GraphQL */ `
  query GetStripeCards($stripeUserId: ID!) {
    getStripeCards(stripeUserId: $stripeUserId)
  }
`;
export const addPaymentMethod = /* GraphQL */ `
  query AddPaymentMethod($stripeUserId: ID!, $stripePaymentMethod: String!) {
    addPaymentMethod(
      stripeUserId: $stripeUserId
      stripePaymentMethod: $stripePaymentMethod
    )
  }
`;
export const getUserInfos = /* GraphQL */ `
  query GetUserInfos($email: String!) {
    getUserInfos(email: $email)
  }
`;
export const getUserAndEcommerceSession = /* GraphQL */ `
  query GetUserAndEcommerceSession($sessionid: String!) {
    getUserAndEcommerceSession(sessionid: $sessionid)
  }
`;
export const notifyOrderPaid = /* GraphQL */ `
  query NotifyOrderPaid($ecommerceSessionId: String!) {
    notifyOrderPaid(ecommerceSessionId: $ecommerceSessionId)
  }
`;
export const createMoonaUserAndPaymentIntentMain = /* GraphQL */ `
  query CreateMoonaUserAndPaymentIntentMain(
    $user: MoonaUserInfos!
    $payment: Payment!
  ) {
    createMoonaUserAndPaymentIntentMain(user: $user, payment: $payment)
  }
`;
export const getPaymentIntentMain = /* GraphQL */ `
  query GetPaymentIntentMain(
    $userId: ID!
    $stripeMerchantId: ID!
    $price: Float!
    $currency: String
    $stripePaymentMethod: String!
    $orderId: String
    $ecommerceSessionId: String
    $discount: Boolean
    $payload: String
  ) {
    getPaymentIntentMain(
      userId: $userId
      stripeMerchantId: $stripeMerchantId
      price: $price
      currency: $currency
      stripePaymentMethod: $stripePaymentMethod
      orderId: $orderId
      ecommerceSessionId: $ecommerceSessionId
      discount: $discount
      payload: $payload
    )
  }
`;
export const subscribeToMoneyBackGuarantee = /* GraphQL */ `
  query SubscribeToMoneyBackGuarantee($stripeUserId: ID!) {
    subscribeToMoneyBackGuarantee(stripeUserId: $stripeUserId)
  }
`;
export const createMoonaMerchant = /* GraphQL */ `
  query CreateMoonaMerchant(
    $email: String!
    $firstname: String!
    $lastname: String!
    $phone: String!
    $website: String!
    $businessDescription: String!
    $companyType: String!
    $annualTurnover: String!
    $ecommerceSolution: String!
    $token: String!
  ) {
    createMoonaMerchant(
      email: $email
      firstname: $firstname
      lastname: $lastname
      phone: $phone
      website: $website
      businessDescription: $businessDescription
      companyType: $companyType
      annualTurnover: $annualTurnover
      ecommerceSolution: $ecommerceSolution
      token: $token
    )
  }
`;
export const finaliseMerchantRegistration = /* GraphQL */ `
  query FinaliseMerchantRegistration($id: ID!, $token: String!) {
    finaliseMerchantRegistration(id: $id, token: $token)
  }
`;
export const getMerchantInformation = /* GraphQL */ `
  query GetMerchantInformation($stripeUserId: ID!) {
    getMerchantInformation(stripeUserId: $stripeUserId)
  }
`;
export const getMerchantBalance = /* GraphQL */ `
  query GetMerchantBalance($stripeUserId: ID!) {
    getMerchantBalance(stripeUserId: $stripeUserId)
  }
`;
export const payoutNow = /* GraphQL */ `
  query PayoutNow($stripeUserId: ID!) {
    payoutNow(stripeUserId: $stripeUserId)
  }
`;
export const getStripeDashboardLink = /* GraphQL */ `
  query GetStripeDashboardLink($stripeUserId: ID!) {
    getStripeDashboardLink(stripeUserId: $stripeUserId)
  }
`;
export const getTotalTransactions = /* GraphQL */ `
  query GetTotalTransactions($stripeUserId: ID!) {
    getTotalTransactions(stripeUserId: $stripeUserId)
  }
`;
export const getTransactionsList = /* GraphQL */ `
  query GetTransactionsList(
    $stripeUserId: ID!
    $offset: String
    $limit: Int
    $email: String
  ) {
    getTransactionsList(
      stripeUserId: $stripeUserId
      offset: $offset
      limit: $limit
      email: $email
    )
  }
`;
export const refundTransactions = /* GraphQL */ `
  query RefundTransactions(
    $transferId: String!
    $transferMoonaId: String
    $transferShippingId: String
    $amount: Int
    $fromDashboard: Boolean
  ) {
    refundTransactions(
      transferId: $transferId
      transferMoonaId: $transferMoonaId
      transferShippingId: $transferShippingId
      amount: $amount
      fromDashboard: $fromDashboard
    )
  }
`;
export const getPayouts = /* GraphQL */ `
  query GetPayouts($stripeUserId: ID!, $offset: String, $limit: Int) {
    getPayouts(stripeUserId: $stripeUserId, offset: $offset, limit: $limit)
  }
`;
export const getPayoutsDetails = /* GraphQL */ `
  query GetPayoutsDetails($stripeUserId: ID!, $payouts: [Payout]) {
    getPayoutsDetails(stripeUserId: $stripeUserId, payouts: $payouts)
  }
`;
export const getNextPayout = /* GraphQL */ `
  query GetNextPayout($stripeUserId: ID!) {
    getNextPayout(stripeUserId: $stripeUserId)
  }
`;
export const getLastPayout = /* GraphQL */ `
  query GetLastPayout($stripeUserId: ID!) {
    getLastPayout(stripeUserId: $stripeUserId)
  }
`;
export const getMerchant = /* GraphQL */ `
  query GetMerchant($id: ID!) {
    getMerchant(id: $id) {
      id
      email
      firstname
      lastname
      phone
      website
      businessDescription
      companyType
      annualTurnover
      ecommerceSolution
      status
      stripeId
      cognitoId
      createdAt
      updatedAt
    }
  }
`;
export const listMerchants = /* GraphQL */ `
  query ListMerchants(
    $filter: ModelMerchantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMerchants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        firstname
        lastname
        phone
        website
        businessDescription
        companyType
        annualTurnover
        ecommerceSolution
        status
        stripeId
        cognitoId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      firstname
      lastname
      phone
      status
      stripeId
      cognitoId
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        firstname
        lastname
        phone
        status
        stripeId
        cognitoId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMerchantApiKeys = /* GraphQL */ `
  query GetMerchantApiKeys($id: ID!) {
    getMerchantApiKeys(id: $id) {
      id
      merchantId
      publishableKeyLive
      publishableKeyTest
      secretKeyLive
      secretKeyTest
      status
      apiVersion
      pluginVersion
      createdAt
      updatedAt
    }
  }
`;
export const listMerchantApiKeyss = /* GraphQL */ `
  query ListMerchantApiKeyss(
    $filter: ModelMerchantApiKeysFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMerchantApiKeyss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        merchantId
        publishableKeyLive
        publishableKeyTest
        secretKeyLive
        secretKeyTest
        status
        apiVersion
        pluginVersion
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEcommerceSession = /* GraphQL */ `
  query GetEcommerceSession($id: ID!) {
    getEcommerceSession(id: $id) {
      id
      merchantId
      stripeMerchantId
      ecommerceSolution
      ecommerceVersion
      website
      apiVersion
      pluginVersion
      amount
      currency
      email
      firstname
      lastname
      phone
      address
      postcode
      city
      country
      language
      returnUrl
      cancelUrl
      notificationUrl
      orderId
      cartId
      deviceId
      mode
      paymentMethod
      payload
      shopifyPayloadCreateOrder
      shopifyDomain
      createdAt
      updatedAt
    }
  }
`;
export const listEcommerceSessions = /* GraphQL */ `
  query ListEcommerceSessions(
    $filter: ModelEcommerceSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEcommerceSessions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        merchantId
        stripeMerchantId
        ecommerceSolution
        ecommerceVersion
        website
        apiVersion
        pluginVersion
        amount
        currency
        email
        firstname
        lastname
        phone
        address
        postcode
        city
        country
        language
        returnUrl
        cancelUrl
        notificationUrl
        orderId
        cartId
        deviceId
        mode
        paymentMethod
        payload
        shopifyPayloadCreateOrder
        shopifyDomain
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomApp = /* GraphQL */ `
  query GetCustomApp($id: ID!) {
    getCustomApp(id: $id) {
      id
      shopifyAppId
      shopifyAppKey
      shopifyAppSecretKey
      appEmbedUUID
      myShopifyDomain
      accessToken
      storefrontAccessToken
      scopes
      createdAt
      updatedAt
    }
  }
`;
export const listCustomApps = /* GraphQL */ `
  query ListCustomApps(
    $filter: ModelCustomAppFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomApps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        shopifyAppId
        shopifyAppKey
        shopifyAppSecretKey
        appEmbedUUID
        myShopifyDomain
        accessToken
        storefrontAccessToken
        scopes
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getShopifyWebhookEvent = /* GraphQL */ `
  query GetShopifyWebhookEvent($id: ID!) {
    getShopifyWebhookEvent(id: $id) {
      id
      createdAt
      updatedAt
    }
  }
`;
export const listShopifyWebhookEvents = /* GraphQL */ `
  query ListShopifyWebhookEvents(
    $filter: ModelShopifyWebhookEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShopifyWebhookEvents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getStoreSettings = /* GraphQL */ `
  query GetStoreSettings($id: ID!) {
    getStoreSettings(id: $id) {
      id
      name
      publishableKeyLive
      publishableKeyTest
      secretKeyLive
      secretKeyTest
      slotMP2
      logoUrl
      checkoutColor
      checkoutButtonColor
      checkoutUrl
      emailMarketing
      smsMarketing
      enabled
      markets {
        name
        code
        primary
      }
      countries {
        id
        name
        code
        tax_name
        tax
      }
      paymentMethods
      fastCheckoutMethods
      storefrontAccessToken
      myShopifyDomain
      trackingCode
      merchantId
      ecommerceSolution
      ecommerceVersion
      apiVersion
      pluginVersion
      shippingSlotAssociatedMethod
      shippingSlotDisplayAssociatedMethod
      shippingSlotEnabled
      shippingSlotLabel
      shippingSlotSort
      serverShopifyUrl
      taxesIncluded
      testMode
      appStatus
      appBlockProductCheckbox {
        slug
        enabled
      }
      createdAt
      updatedAt
    }
  }
`;
export const listStoreSettingss = /* GraphQL */ `
  query ListStoreSettingss(
    $filter: ModelStoreSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStoreSettingss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        publishableKeyLive
        publishableKeyTest
        secretKeyLive
        secretKeyTest
        slotMP2
        logoUrl
        checkoutColor
        checkoutButtonColor
        checkoutUrl
        emailMarketing
        smsMarketing
        enabled
        paymentMethods
        fastCheckoutMethods
        storefrontAccessToken
        myShopifyDomain
        trackingCode
        merchantId
        ecommerceSolution
        ecommerceVersion
        apiVersion
        pluginVersion
        shippingSlotAssociatedMethod
        shippingSlotDisplayAssociatedMethod
        shippingSlotEnabled
        shippingSlotLabel
        shippingSlotSort
        serverShopifyUrl
        taxesIncluded
        testMode
        appStatus
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCheckoutSession = /* GraphQL */ `
  query GetCheckoutSession($id: ID!) {
    getCheckoutSession(id: $id) {
      id
      myShopifyDomain
      customer {
        id
        firstName
        lastName
        acceptsMarketing
        email
        phone
      }
      cart {
        id
        note
        attributes
        itemCount
        requiresShipping
        currency
      }
      store {
        country
        currency
        language
        myShopifyDomain
        customizer
        appMode
        role
      }
      order {
        id
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCheckoutSessions = /* GraphQL */ `
  query ListCheckoutSessions(
    $filter: ModelCheckoutSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCheckoutSessions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        myShopifyDomain
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const merchantsByEmail = /* GraphQL */ `
  query MerchantsByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelMerchantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    merchantsByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        firstname
        lastname
        phone
        website
        businessDescription
        companyType
        annualTurnover
        ecommerceSolution
        status
        stripeId
        cognitoId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const usersByEmail = /* GraphQL */ `
  query UsersByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        firstname
        lastname
        phone
        status
        stripeId
        cognitoId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const keysByMerchantId = /* GraphQL */ `
  query KeysByMerchantId(
    $merchantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMerchantApiKeysFilterInput
    $limit: Int
    $nextToken: String
  ) {
    keysByMerchantId(
      merchantId: $merchantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        merchantId
        publishableKeyLive
        publishableKeyTest
        secretKeyLive
        secretKeyTest
        status
        apiVersion
        pluginVersion
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const bySecretKeyLive = /* GraphQL */ `
  query BySecretKeyLive(
    $secretKeyLive: String
    $sortDirection: ModelSortDirection
    $filter: ModelMerchantApiKeysFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bySecretKeyLive(
      secretKeyLive: $secretKeyLive
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        merchantId
        publishableKeyLive
        publishableKeyTest
        secretKeyLive
        secretKeyTest
        status
        apiVersion
        pluginVersion
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const bySecretKeyTest = /* GraphQL */ `
  query BySecretKeyTest(
    $secretKeyTest: String
    $sortDirection: ModelSortDirection
    $filter: ModelMerchantApiKeysFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bySecretKeyTest(
      secretKeyTest: $secretKeyTest
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        merchantId
        publishableKeyLive
        publishableKeyTest
        secretKeyLive
        secretKeyTest
        status
        apiVersion
        pluginVersion
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byShopifyDomainFromCustomApp = /* GraphQL */ `
  query ByShopifyDomainFromCustomApp(
    $myShopifyDomain: String
    $sortDirection: ModelSortDirection
    $filter: ModelCustomAppFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byShopifyDomainFromCustomApp(
      myShopifyDomain: $myShopifyDomain
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shopifyAppId
        shopifyAppKey
        shopifyAppSecretKey
        appEmbedUUID
        myShopifyDomain
        accessToken
        storefrontAccessToken
        scopes
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byShopifyDomainFromStoreSettings = /* GraphQL */ `
  query ByShopifyDomainFromStoreSettings(
    $myShopifyDomain: String
    $sortDirection: ModelSortDirection
    $filter: ModelStoreSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byShopifyDomainFromStoreSettings(
      myShopifyDomain: $myShopifyDomain
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        publishableKeyLive
        publishableKeyTest
        secretKeyLive
        secretKeyTest
        slotMP2
        logoUrl
        checkoutColor
        checkoutButtonColor
        checkoutUrl
        emailMarketing
        smsMarketing
        enabled
        paymentMethods
        fastCheckoutMethods
        storefrontAccessToken
        myShopifyDomain
        trackingCode
        merchantId
        ecommerceSolution
        ecommerceVersion
        apiVersion
        pluginVersion
        shippingSlotAssociatedMethod
        shippingSlotDisplayAssociatedMethod
        shippingSlotEnabled
        shippingSlotLabel
        shippingSlotSort
        serverShopifyUrl
        taxesIncluded
        testMode
        appStatus
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const bySecretKeyLiveFromStoreSettings = /* GraphQL */ `
  query BySecretKeyLiveFromStoreSettings(
    $secretKeyLive: String
    $sortDirection: ModelSortDirection
    $filter: ModelStoreSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bySecretKeyLiveFromStoreSettings(
      secretKeyLive: $secretKeyLive
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        publishableKeyLive
        publishableKeyTest
        secretKeyLive
        secretKeyTest
        slotMP2
        logoUrl
        checkoutColor
        checkoutButtonColor
        checkoutUrl
        emailMarketing
        smsMarketing
        enabled
        paymentMethods
        fastCheckoutMethods
        storefrontAccessToken
        myShopifyDomain
        trackingCode
        merchantId
        ecommerceSolution
        ecommerceVersion
        apiVersion
        pluginVersion
        shippingSlotAssociatedMethod
        shippingSlotDisplayAssociatedMethod
        shippingSlotEnabled
        shippingSlotLabel
        shippingSlotSort
        serverShopifyUrl
        taxesIncluded
        testMode
        appStatus
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const bySecretKeyTestFromStoreSettings = /* GraphQL */ `
  query BySecretKeyTestFromStoreSettings(
    $secretKeyTest: String
    $sortDirection: ModelSortDirection
    $filter: ModelStoreSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bySecretKeyTestFromStoreSettings(
      secretKeyTest: $secretKeyTest
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        publishableKeyLive
        publishableKeyTest
        secretKeyLive
        secretKeyTest
        slotMP2
        logoUrl
        checkoutColor
        checkoutButtonColor
        checkoutUrl
        emailMarketing
        smsMarketing
        enabled
        paymentMethods
        fastCheckoutMethods
        storefrontAccessToken
        myShopifyDomain
        trackingCode
        merchantId
        ecommerceSolution
        ecommerceVersion
        apiVersion
        pluginVersion
        shippingSlotAssociatedMethod
        shippingSlotDisplayAssociatedMethod
        shippingSlotEnabled
        shippingSlotLabel
        shippingSlotSort
        serverShopifyUrl
        taxesIncluded
        testMode
        appStatus
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byShopifyDomainFromCheckoutSession = /* GraphQL */ `
  query ByShopifyDomainFromCheckoutSession(
    $myShopifyDomain: String
    $sortDirection: ModelSortDirection
    $filter: ModelCheckoutSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byShopifyDomainFromCheckoutSession(
      myShopifyDomain: $myShopifyDomain
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        myShopifyDomain
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

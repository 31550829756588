import {
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  SET_EMAIL_MOONAID_SECRET,
  USER_ERROR,
  GET_MAIL,
  GET_MAIL_SUCCESS,
  SET_FIRST_PWD,
  UPDATE_PWD,
  SET_PWD_SUCCESS,
  LOAD_USER,
  CLEAR_USER,
  FINALIZE_REGISTRATION,
  LOAD_USER_ACCOUNT_LINK,
  LOAD_USER_ACCOUNT_LINK_SUCCESS,
  LOAD_KEYS,
  SET_KEYS,
  User,
  KEYS
} from './types'

import { AppActions } from '../types'

export const createUserRequest = ({
  businessUrl,
  firstname,
  lastname,
  country,
  email,
  phone,
  password,
  businessDescription,
  companyType,
  annualTurnover,
  ecommerceSolution
}: {
  businessUrl: string
  firstname: string
  lastname: string
  country: string
  email: string
  phone: string
  password: string
  businessDescription: string
  companyType: string
  annualTurnover: string
  ecommerceSolution: string
}): AppActions => ({
  type: CREATE_USER_REQUEST,
  payload: {
    businessUrl,
    firstname,
    lastname,
    country,
    email,
    phone,
    password,
    businessDescription,
    companyType,
    annualTurnover,
    ecommerceSolution
  }
})

export const createUserSuccess = (): AppActions => ({
  type: CREATE_USER_SUCCESS
})
export const createUserFailure = ({
  error
}: {
  error: string
}): AppActions => ({
  type: CREATE_USER_FAILURE,
  payload: {
    error
  }
})

export const loadUser = ({
  id,
  stripeId,
  firstName,
  lastName,
  email,
  phone,
  businessUrl,
  businessDescription,
  companyType,
  status
}: User): AppActions => ({
  type: LOAD_USER,
  payload: {
    id,
    stripeId,
    firstName,
    lastName,
    email,
    phone,
    businessUrl,
    businessDescription,
    companyType,
    status
  }
})

export const clearUser = (): AppActions => ({
  type: CLEAR_USER
})

export const setUserMailAndId = ({
  email,
  moonaId
}: {
  email: string
  moonaId: string
}): AppActions => {
  return {
    type: SET_EMAIL_MOONAID_SECRET,
    payload: {
      email,
      moonaId
    }
  }
}

export const userError = ({ error }: { error: string }): AppActions => ({
  type: USER_ERROR,
  payload: {
    error
  }
})

export const setFirstPwd = ({
  email,
  password
}: {
  email: string
  password: string
}): AppActions => ({
  type: SET_FIRST_PWD,
  payload: {
    email,
    password
  }
})

export const updatePwd = ({
  code,
  username,
  password
}: {
  code: string
  username: string
  password: string
}): AppActions => ({
  type: UPDATE_PWD,
  payload: {
    username,
    code,
    password
  }
})

export const setPwdSuccess = ({
  password
}: {
  password: string
}): AppActions => {
  return {
    type: SET_PWD_SUCCESS,
    payload: {
      password
    }
  }
}

export const fetchMail = (id: string): AppActions => ({
  type: GET_MAIL,
  payload: {
    id
  }
})

export const fetchMailSuccess = ({ email }: { email: string }): AppActions => ({
  type: GET_MAIL_SUCCESS,
  payload: { email }
})

export const fetchAccountLink = (stripeId: string): AppActions => ({
  type: LOAD_USER_ACCOUNT_LINK,
  payload: {
    stripeId
  }
})

export const fetchAccountLinkSuccess = ({
  accountLink
}: {
  accountLink: string
}): AppActions => ({
  type: LOAD_USER_ACCOUNT_LINK_SUCCESS,
  payload: { accountLink }
})

export const finalizeRegistration = ({
  code,
  state
}: {
  code: string
  state: string
}): AppActions => ({
  type: FINALIZE_REGISTRATION,
  payload: { code, state }
})

export const loadKeys = ({ id }: { id: string }): AppActions => ({
  type: LOAD_KEYS,
  payload: {
    id
  }
})

export const setKeys = (keys: KEYS): AppActions => ({
  type: SET_KEYS,
  payload: {
    keys
  }
})

import { AppState } from './features/types'

const TTL = 30 * 60 * 1000 // 30minutes
// type PERSISTED_STATE = Pick<AppState, 'users' | 'auth' | 'payment' | 'query'>

type ITEM = {
  expiry: number
  data: AppState
}
export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state')
    if (serializedState === null) {
      return undefined
    }
    const item: ITEM = JSON.parse(serializedState)
    const now = new Date()
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return undefined so reducers inititialized themselves on their own
      localStorage.removeItem('state')
      return undefined
    }

    return item.data
  } catch (err) {
    return undefined
  }
}

export const deleteState = () => {
  try {
    localStorage.removeItem('state')
  } catch (err) {
    console.log('err', err)
    return undefined
  }
}

export const saveState = (state: AppState) => {
  try {
    const now = new Date()
    const item: ITEM = {
      data: {
        ...state,
        auth: {
          ...state.auth,
          forgotPasswordLoading: false,
          forgotPasswordSuccess: false,
          forgotPasswordFailure: false,
          finalizeRegistrationStatus: 'pending',
          loginfailure: false,
          error: ''
        },
        users: {
          ...state.users,
          keys: {
            publishableKeyLive: '',
            publishableKeyTest: '',
            secretKeyLive: '',
            secretKeyTest: ''
          }
        },
        payouts: {
          ...state.payouts,
          header: {
            error: null,
            success: false,
            loading: false,
            nextPayout: null,
            lastPayout: null
          },
          table: {
            rows: [],
            filter: '',
            pageIndex: 0,
            hasMore: false,
            error: null,
            success: false,
            loading: false
          }
        },
        payout: {
          ...state.payout,
          payoutsEnabledLoading: true
        }
      },
      expiry: now.getTime() + TTL
    }
    const serializedState = JSON.stringify(item)
    localStorage.setItem('state', serializedState)
  } catch (err) {
    // Ignore write errors.
  }
}

import {
  TransactionsState,
  TransactionsActionTypes,
  LOAD_TRANSACTIONS_REQUEST,
  LOAD_TRANSACTIONS_ERROR,
  LOAD_TRANSACTIONS_SUCCESS,
  LOAD_TRANSACTIONS_FAILURE,
  RESET_TRANSACTIONS
} from './types'

export const initialTransactionsState: TransactionsState = {
  loading: false,
  success: false,
  failure: false,
  error: '',
  total: 0,
  moonaFunding: 0
}

export default function transactions(
  state = initialTransactionsState,
  action: TransactionsActionTypes
): TransactionsState {
  switch (action.type) {
    case LOAD_TRANSACTIONS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case LOAD_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        failure: false,
        total: action.payload.total,
        moonaFunding: action.payload.moonaFunding,
        error: ''
      }
    case LOAD_TRANSACTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        failure: true,
        error: action.payload.error
      }

    case LOAD_TRANSACTIONS_ERROR:
      return {
        ...state,
        error: action.payload.error
      }
    case RESET_TRANSACTIONS:
      return {
        ...initialTransactionsState
      }

    default:
      return state
  }
}

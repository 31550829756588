import {
  LOGIN,
  LOGOUT,
  LOGGED_IN,
  LOGGABLE,
  LOGGABLE_SUCESS,
  LOGIN_FAIL,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_RESET,
  FINALIZE_REGISTRATION_STATUS,
  BackMessages
} from './types'
import { AppActions } from '../types'

export const loginRequest = (login: string, password: string): AppActions => {
  return {
    type: LOGIN,
    payload: {
      login,
      password
    }
  }
}

export const logoutRequest = (): AppActions => {
  return {
    type: LOGOUT
  }
}

export const loggedIn = (): AppActions => {
  return {
    type: LOGGED_IN
  }
}
export const loginFail = (): AppActions => {
  return {
    type: LOGIN_FAIL
  }
}

export const loggable = (userId: string): AppActions => {
  return {
    type: LOGGABLE,
    payload: {
      userId
    }
  }
}

export const loggableSuccess = (loggable: boolean): AppActions => {
  return {
    type: LOGGABLE_SUCESS,
    payload: {
      loggable
    }
  }
}

export const createForgotPasswordSuccess = (): AppActions => ({
  type: FORGOT_PASSWORD_SUCCESS
})
export const createForgotPasswordFailure = ({
  error
}: {
  error: string
}): AppActions => ({
  type: FORGOT_PASSWORD_FAILURE,
  payload: {
    error
  }
})

export const createForgotPasswordError = ({
  error
}: {
  error: string
}): AppActions => ({
  type: FORGOT_PASSWORD_ERROR,
  payload: {
    error
  }
})
export const resetForgotPassword = (): AppActions => ({
  type: FORGOT_PASSWORD_RESET
})

export const createForgotPasswordRequest = ({
  login,
  messages
}: {
  login: string
  messages: BackMessages
}): AppActions => ({
  type: FORGOT_PASSWORD,
  payload: {
    login,
    messages
  }
})

export const setFinalizeRegistrationStatus = ({
  status
}: {
  status: string
}): AppActions => ({
  type: FINALIZE_REGISTRATION_STATUS,
  payload: {
    finalizeRegistrationStatus: status
  }
})

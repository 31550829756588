export const LOAD_PAYOUTS_HEADER_REQUEST = 'LOAD_PAYOUTS_HEADER_REQUEST'
export const LOAD_PAYOUTS_HEADER_SUCCESS = 'LOAD_PAYOUTS_HEADER_SUCCESS'
export const LOAD_PAYOUTS_HEADER_FAILURE = 'LOAD_PAYOUTS_HEADER_FAILURE'

export const LOAD_PAYOUTS_TABLE_REQUEST = 'LOAD_PAYOUTS_TABLE_REQUEST'
export const LOAD_PAYOUTS_TABLE_SUCCESS = 'LOAD_PAYOUTS_TABLE_SUCCESS'
export const LOAD_PAYOUTS_TABLE_FAILURE = 'LOAD_PAYOUTS_TABLE_FAILURE'

export const CHANGE_PAYOUTS_TABLE_FILTER = 'CHANGE_PAYOUTS_TABLE_FILTER'

export type Payout = {
  id: string
  created: number
  status: string
  arrival_date: number
  amount: number
  bank: {
    bank_name: string
    last4: string
    deleted: boolean | null
  }
}

export type PayoutColumn = {
  id: string
  created: number
  amount: number
  status: string
  arrivalDate: number
  bank: {
    bank_name: string
    last4: string
    deleted: boolean | null
  }
}

export type PayoutDetail = {
  order_id: string
  customer_email: string
  order_amount: number
  payment_date: number
}

export type PayoutCsvRow = PayoutDetail & {
  payout_id: string
}

export interface PayoutsState {
  header: {
    loading: boolean
    success: boolean
    error: string | null
    nextPayout: Payout | null
    lastPayout: Payout | null
  }
  table: {
    loading: boolean
    success: boolean
    error: string | null
    hasMore: boolean
    pageIndex: number
    filter: string
    rows: Array<PayoutColumn>
  }
}

export interface LoadPayoutsHeaderRequest {
  type: typeof LOAD_PAYOUTS_HEADER_REQUEST
  payload: {
    stripeId: string
  }
}

export interface LoadPayoutsHeaderSuccess {
  type: typeof LOAD_PAYOUTS_HEADER_SUCCESS
  payload: {
    nextPayout: Payout | null
    lastPayout: Payout | null
  }
}

export interface LoadPayoutsHeaderFailure {
  type: typeof LOAD_PAYOUTS_HEADER_FAILURE
  payload: {
    error: string
  }
}

export interface LoadPayoutsTableRequest {
  type: typeof LOAD_PAYOUTS_TABLE_REQUEST
  payload: {
    stripeId: string
    offset?: string
    limit?: number
  }
}

export interface LoadPayoutsTableSuccess {
  type: typeof LOAD_PAYOUTS_TABLE_SUCCESS
  payload: {
    rows: Array<PayoutColumn>
    hasMore: boolean
    append?: boolean
  }
}

export interface LoadPayoutsTableFailure {
  type: typeof LOAD_PAYOUTS_TABLE_FAILURE
  payload: {
    error: string
  }
}

export interface ChangePayoutsTableFilter {
  type: typeof CHANGE_PAYOUTS_TABLE_FILTER
  payload: {
    filter: string
  }
}

export type PayoutsActionTypes =
  | LoadPayoutsHeaderRequest
  | LoadPayoutsHeaderSuccess
  | LoadPayoutsHeaderFailure
  | LoadPayoutsTableRequest
  | LoadPayoutsTableSuccess
  | LoadPayoutsTableFailure
  | ChangePayoutsTableFilter

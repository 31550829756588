export const LOAD_TRANSACTIONS_REQUEST = 'LOAD_TRANSACTIONS_REQUEST'
export const LOAD_TRANSACTIONS_SUCCESS = 'LOAD_TRANSACTIONS_SUCCESS'
export const LOAD_TRANSACTIONS_FAILURE = 'LOAD_TRANSACTIONS_FAILURE'
export const LOAD_TRANSACTIONS_ERROR = 'LOAD_TRANSACTIONS_ERROR'
export const RESET_TRANSACTIONS = 'RESET_TRANSACTIONS'

export interface LoadTransactionsRequest {
  type: typeof LOAD_TRANSACTIONS_REQUEST
  payload: { stripeId: string }
}
export interface LoadTransactionsSuccess {
  type: typeof LOAD_TRANSACTIONS_SUCCESS
  payload: {
    total: number
    moonaFunding: number
  }
}
export interface LoadTransactionsFailure {
  type: typeof LOAD_TRANSACTIONS_FAILURE
  payload: {
    error: string
  }
}

export interface LoadTransactionsError {
  type: typeof LOAD_TRANSACTIONS_ERROR
  payload: {
    error: string
  }
}
export interface ResetTransactions {
  type: typeof RESET_TRANSACTIONS
}

export interface TransactionsState {
  loading: boolean
  success: boolean
  failure: boolean
  error: string
  total: number
  moonaFunding: number
}

export type TransactionsActionTypes =
  | LoadTransactionsRequest
  | LoadTransactionsError
  | LoadTransactionsSuccess
  | LoadTransactionsFailure
  | ResetTransactions

import {
  UsersState,
  SET_FIRST_PWD,
  UPDATE_PWD,
  SET_PWD_SUCCESS,
  USER_ERROR,
  GET_MAIL_SUCCESS,
  LOAD_USER,
  CLEAR_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  LOAD_USER_ACCOUNT_LINK_SUCCESS,
  SET_KEYS,
  UsersActionTypes
} from './types'

export const initialUserState: UsersState = {
  password: '',
  passwordUpdated: false,
  email: '',
  loading: false,
  userloaded: false, // false
  error: '',
  id: '',
  phone: '',
  firstName: '',
  lastName: '',
  success: false,
  failure: false,
  stripeId: '',
  businessUrl: '',
  businessDescription: '',
  companyType: '',
  annualTurnover: '',
  ecommerceSolution: '',
  accountLink: '',
  status: ''
}

export default function users(
  state = initialUserState,
  action: UsersActionTypes
): UsersState {
  switch (action.type) {
    case SET_FIRST_PWD:
      return {
        ...state,
        loading: true,
        passwordUpdated: false
      }
    case UPDATE_PWD:
      return {
        ...state,
        loading: true,
        passwordUpdated: false
      }
    case SET_PWD_SUCCESS:
      return {
        ...state,
        loading: false,
        passwordUpdated: true,
        error: '',
        password: action.payload.password
      }
    case GET_MAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        email: action.payload.email
      }
    case LOAD_USER:
      return {
        ...state,
        email: action.payload.email,
        id: action.payload.id,
        phone: action.payload.phone,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,

        stripeId: action.payload.stripeId,
        businessUrl: action.payload.businessUrl,
        businessDescription: action.payload.businessDescription,
        companyType: action.payload.companyType,
        annualTurnover: action.payload.annualTurnover,
        ecommerceSolution: action.payload.ecommerceSolution,
        status: action.payload.status,
        userloaded: true
      }
    case CLEAR_USER:
      return {
        ...initialUserState
      }

    case CREATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        failure: false,
        error: ''
      }
    case CREATE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        failure: true,
        error: action.payload.error
      }

    case USER_ERROR:
      return {
        ...state,
        error: action.payload.error
      }
    case LOAD_USER_ACCOUNT_LINK_SUCCESS:
      return {
        ...state,
        accountLink: action.payload.accountLink
      }
    case SET_KEYS:
      return {
        ...state,
        keys: action.payload.keys
      }

    default:
      return state
  }
}

export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const LOGGED_IN = 'LOGGED_IN'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGGABLE = 'LOGGABLE'
export const LOGGABLE_SUCESS = 'LOGGABLE_SUCCESS'
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE'
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR'
export const FORGOT_PASSWORD_RESET = 'FORGOT_PASSWORD_RESET'
export const FINALIZE_REGISTRATION_STATUS = 'FINALIZE_REGISTRATION_STATUS'

export type BackMessages = {
  limitExceededException: string
  defaultException: string
}
export interface Credentials {
  login: string
  password: string
}

export interface LogginAction {
  type: typeof LOGIN
  payload: Credentials
}

export interface Logout {
  type: typeof LOGOUT
}

export interface LoggedInAction {
  type: typeof LOGGED_IN
}

export interface LoginFailAction {
  type: typeof LOGIN_FAIL
}

export interface LoggableAction {
  type: typeof LOGGABLE
  payload: {
    userId: string
  }
}

export interface LoggableSucessAction {
  type: typeof LOGGABLE_SUCESS
  payload: {
    loggable: boolean
  }
}

export interface ForgotPasswordAction {
  type: typeof FORGOT_PASSWORD
  payload: {
    login: string
    messages: BackMessages
  }
}

export interface ForgotPasswordSuccess {
  type: typeof FORGOT_PASSWORD_SUCCESS
}

export interface ForgotPasswordFailure {
  type: typeof FORGOT_PASSWORD_FAILURE
  payload: {
    error: string
  }
}

export interface ForgotPasswordError {
  type: typeof FORGOT_PASSWORD_ERROR
  payload: {
    error: string
  }
}
export interface ForgotPasswordReset {
  type: typeof FORGOT_PASSWORD_RESET
}

export interface SetFinalizeRegistrationStatus {
  type: typeof FINALIZE_REGISTRATION_STATUS
  payload: {
    finalizeRegistrationStatus: string
  }
}

export type AuthActionTypes =
  | LogginAction
  | LoggedInAction
  | LoginFailAction
  | LoggableAction
  | LoggableSucessAction
  | Logout
  | ForgotPasswordAction
  | ForgotPasswordSuccess
  | ForgotPasswordFailure
  | ForgotPasswordError
  | ForgotPasswordReset
  | SetFinalizeRegistrationStatus
export interface AuthState {
  loading: boolean
  logged: boolean
  loginfailure: boolean
  registered: boolean
  error: string
  forgotPasswordLoading: boolean
  forgotPasswordSuccess: boolean
  forgotPasswordFailure: boolean
  finalizeRegistrationStatus: string
}

import {
  CREATE_REFUND_REQUEST,
  CREATE_REFUND_SUCCESS,
  CREATE_REFUND_FAILURE,
  RESET_REFUND
} from './types'

import { AppActions } from '../types'

export const createRefundRequest = ({
  stripeId,
  transferId,
  transferMoonaId,
  transferShippingId,
  amount,
  payback,
  customer,
  currency,
  fromDashboard
}: {
  stripeId: string
  transferId: string
  transferMoonaId: string
  transferShippingId: string
  amount: number
  payback: number
  customer: string
  currency: string
  fromDashboard: boolean
}): AppActions => ({
  type: CREATE_REFUND_REQUEST,
  payload: {
    stripeId,
    transferId,
    transferMoonaId,
    transferShippingId,
    amount,
    payback,
    customer,
    currency,
    fromDashboard
  }
})
export const createRefundSuccess = ({
  amount,
  payback,
  isFullRefund,
  currency
}: {
  amount: number
  payback: number
  isFullRefund: boolean
  currency: string
}): AppActions => ({
  type: CREATE_REFUND_SUCCESS,
  payload: {
    amount,
    payback,
    isFullRefund,
    currency
  }
})
export const createRefundFailure = ({
  error,
  amount,
  payback,
  currency
}: {
  error: string
  amount: number
  payback: number
  currency: string
}): AppActions => ({
  type: CREATE_REFUND_FAILURE,
  payload: {
    error,
    amount,
    payback,
    currency
  }
})

export const resetRefund = (): AppActions => ({
  type: RESET_REFUND
})

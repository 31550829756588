import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
    :root {

        --color-black: #1C193E;
        --color-black-moona: #3F4045;
        --color-black-moona-02: #292B36;

        --color-success:#31DC9E;
        --color-success-hover: #1BC185;
        --color-success-disable:rgba(49, 220, 158, 0.5);
        --color-success-border: #BAFFE6;

        --color-danger:#FF4773;
        --color-danger-hover: #D72650;
        --color-danger-disable:rgba(255, 71, 115, 0.5);
        --color-danger-border: #FFA6BB;

        --color-grey-light: #E8E9ED;
        --color-grey-main: #87888D;
        --color-grey-light-1: #F5F6FA;
        --color-grey-dark: #F5F6FA;
        --color-grey-link: #C4C4C4;
        --color-red-danger: #FF4773;

        --color-purple-moona: #9540FF;
        --color-purple-dark:  #6C1FCB;
        --color-purple-light:#ECDDFF;






    }


    * {
        margin:0;
        padding:0;
    }

    *, *::before, *::after {
        box-sizing:inherit;
    }

    html {
        box-sizing:border-box;
        font-size:62.5%
    }
    body {
        font-family:'Aeonik';

        min-height: 100vh;
    }
`

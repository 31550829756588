import {
  SessionState,
  SessionActionTypes,
  SET_DEVICE_TYPE,
  SET_CONTACT_US
} from './types'

export const initialSessionState: SessionState = {
  isMobile: false,
  showContactUs: false
}

export default function payment(
  state = initialSessionState,
  action: SessionActionTypes
): SessionState {
  switch (action.type) {
    case SET_DEVICE_TYPE:
      return {
        ...state,
        isMobile: action.payload.isMobile
      }
    case SET_CONTACT_US:
      return {
        ...state,
        showContactUs: action.payload.showContactUs
      }

    default:
      return state
  }
}

import {
  CLEAR_SEARCH_EMAIL,
  LOAD_DATA_ERROR,
  LOAD_DATA_FAILURE,
  LOAD_DATA_REQUEST,
  LOAD_DATA_SUCCESS,
  RESET_DATA,
  SET_SEARCH_EMAIL,
  TransactionRow
} from './types'

import { AppActions } from '../types'

export const loadTableRequest = (
  stripeId: string,
  options?: Partial<{ offset: string; limit: number; email: string }>
): AppActions => {
  const isEmailSearch = options?.email !== undefined && options.email !== ''
  return {
    type: LOAD_DATA_REQUEST,
    payload: {
      stripeId: stripeId,
      limit: !isEmailSearch ? options?.limit : undefined,
      offset: options?.offset,
      email: isEmailSearch ? options?.email : undefined
    }
  }
}
export const loadTableSuccess = ({
  data,
  hasMore,
  append
}: {
  data: TransactionRow[]
  hasMore: boolean
  append?: boolean
}): AppActions => ({
  type: LOAD_DATA_SUCCESS,
  payload: {
    data,
    append,
    hasMore
  }
})
export const loadTableFailure = ({ error }: { error: string }): AppActions => ({
  type: LOAD_DATA_FAILURE,
  payload: {
    error
  }
})

export const loadTableError = ({ error }: { error: string }): AppActions => ({
  type: LOAD_DATA_ERROR,
  payload: {
    error
  }
})
export const resetTable = (): AppActions => ({
  type: RESET_DATA
})

export const setSearchEmail = (value: string): AppActions => ({
  type: SET_SEARCH_EMAIL,
  payload: {
    value
  }
})

export const clearSearchEmail = (): AppActions => ({
  type: CLEAR_SEARCH_EMAIL
})

import React from 'react'
import styled from 'styled-components'
import { AppState } from 'state/features/types'
import { useSelector } from 'react-redux'
import {
  LoginSection,
  CenteredMain
} from '../../common/style/components/Section'
import { BaseSkeleton } from 'common/components/BaseSkeleton/BaseSkeleton'

import { SkelLoginForm } from './components/SkelLoginForm'
import { tablet } from 'common/style/queries/media-queries'

export const LoginCenteredMain = styled(CenteredMain)`
  width: 33.5rem;
  padding: 0.6rem 2.4rem 2.6rem 2.4rem;

  background-color: #27244b;
  border: 1px solid #e8e9ed;
  border-radius: 16px;

  @media ${tablet} {
    padding: 6.4rem 17.6rem 6.5rem 17.6rem;
    width: 100%;
  }
`

export const Center = styled.div`
  align-self: center;
`
export const Left = styled.div`
  align-self: flex-start;
`

export const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media ${tablet} {
  }
`
const SkelLogin: React.FC = () => {
  const { isMobile } = useSelector((state: AppState) => state.session)

  return (
    <LoginSection>
      <LoginWrapper>
        {isMobile && (
          <Center>
            <BaseSkeleton width={80} height={17} mt="3.6rem" mb="3.6rem" />
          </Center>
        )}
        <LoginCenteredMain>
          {!isMobile && <BaseSkeleton width={101} height={24} />}
          <Left>
            <BaseSkeleton width={148} mt="3rem" height={21} />
          </Left>
          <SkelLoginForm />
          <BaseSkeleton width={131} height={17} mt="2.3rem" />
        </LoginCenteredMain>
      </LoginWrapper>
    </LoginSection>
  )
}

export default SkelLogin

import { put, takeEvery, fork, call } from 'redux-saga/effects'

import { CREATE_REFUND_REQUEST, CreateRefundRequest } from './types'
import { createRefundSuccess, createRefundFailure } from './actions'
import { API, graphqlOperation } from 'aws-amplify'
import { check } from '../users/utils'
import { refundTransactions } from 'graphql/queries'
import { loadPayoutRequest } from '../payout/actions'
import { clearSearchEmail, loadTableRequest } from '../table/actions'
import { createTransactionsRequest } from '../transactions/actions'

function* refund(action: CreateRefundRequest) {
  const {
    stripeId,
    transferId,
    transferMoonaId,
    transferShippingId,
    amount,
    payback,
    currency,
    fromDashboard
  } = action.payload
  try {
    const refundResult = yield call(
      [API, 'graphql'],
      graphqlOperation(refundTransactions, {
        transferId,
        transferMoonaId,
        transferShippingId,
        amount,
        fromDashboard
      })
    )

    check(refundResult, 'refundTransactions')
    const { isFullRefund } = JSON.parse(refundResult.data.refundTransactions)

    yield put(clearSearchEmail())
    yield put(loadPayoutRequest(stripeId))
    yield put(loadTableRequest(stripeId))
    yield put(createTransactionsRequest(stripeId))

    yield put(
      createRefundSuccess({
        isFullRefund,
        amount,
        payback,
        currency
      })
    )

    /* To test success */
    // yield put(
    //   createRefundSuccess({
    //     amount: 5,
    //     payback: 5
    //   })
    // )

    /* To test failure */
    // yield put(createRefundFailure({ error: 'error from stripe' }))
  } catch (error) {
    yield put(
      createRefundFailure({
        error: 'error from stripe',
        amount,
        payback,
        currency
      })
    )
  }
}

function* watchRefund() {
  yield takeEvery(CREATE_REFUND_REQUEST, refund)
}

const refundSagas = [fork(watchRefund)]
export default refundSagas

import React from 'react'
import styled from 'styled-components'
import { AppState } from 'state/features/types'
import { useSelector } from 'react-redux'
import { Section } from 'common/style/components/Section'
import { BaseSkeleton } from 'common/components/BaseSkeleton/BaseSkeleton'

import { tablet } from 'common/style/queries/media-queries'
import { Left } from '../SkelLogin'

export const LoginFormContainer = styled(Section)`
  margin-top: 3.2rem;
  width: 100%;
  @media ${tablet} {
  }
`

export const FormWrapper = styled.div`
  width: 100%;
`

export const LoginFormSection = styled(Section)`
  margin: 0;
  width: 100%;
  align-items: center;

  @media ${tablet} {
    width: 32.4rem;
  }

  & > div > label {
    color: #ffffff;
  }
`

export const SkelLoginForm: React.FC = () => {
  const { isMobile } = useSelector((state: AppState) => state.session)

  const inputWidth = isMobile ? 283 : 324
  return (
    <LoginFormContainer>
      <FormWrapper>
        <LoginFormSection className={'login-form-section'}>
          <Left>
            <BaseSkeleton width={43} height={14} />
          </Left>
          <BaseSkeleton width={inputWidth} height={41} mt="1rem" />
          <Left>
            <BaseSkeleton width={68} height={14} mt="2.3rem" />
          </Left>
          <BaseSkeleton width={inputWidth} height={41} mt="1rem" />
          <BaseSkeleton width={inputWidth} height={50} mt="1.5rem" />
        </LoginFormSection>
      </FormWrapper>
    </LoginFormContainer>
  )
}

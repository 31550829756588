import { applyMiddleware, createStore, Store, compose } from 'redux'
import { rootReducer, rootSaga } from './features'
import sagaMiddleware from './middlewares/sagas'
import { AppState } from './features/types'
import { loadState, saveState } from './localStorage'
import throttle from 'lodash/throttle'

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: <R>(a: R) => R
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default function configureStore(): Store<AppState> {
  const persistedState = loadState()
  const middlewares = applyMiddleware(sagaMiddleware)
  const store = createStore(
    rootReducer,
    persistedState,
    composeEnhancers(middlewares)
  )
  sagaMiddleware.run(rootSaga)

  store.subscribe(
    throttle(() => {
      saveState({
        users: store.getState().users,
        payouts: store.getState().payouts,
        auth: store.getState().auth,
        payout: store.getState().payout,
        session: store.getState().session,
        refund: store.getState().refund,
        table: store.getState().table,
        transactions: store.getState().transactions
      })
    }, 1000)
  )

  return store
}

import {
  RefundState,
  RefundActionTypes,
  CREATE_REFUND_REQUEST,
  CREATE_REFUND_SUCCESS,
  CREATE_REFUND_FAILURE,
  RESET_REFUND
} from './types'

export const initialRefundState: RefundState = {
  loading: false,
  success: false,
  failure: false,
  error: '',
  isFullRefund: false,
  amount: 0,
  payback: 0,
  customer: '',
  currency: 'GBP'
}

export default function payout(
  state = initialRefundState,
  action: RefundActionTypes
): RefundState {
  switch (action.type) {
    case CREATE_REFUND_REQUEST:
      return {
        ...state,
        loading: true,
        customer: action.payload.customer
      }
    case CREATE_REFUND_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        failure: false,
        amount: action.payload.amount,
        payback: action.payload.payback,
        isFullRefund: action.payload.isFullRefund,
        error: '',
        currency: action.payload.currency
      }
    case CREATE_REFUND_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        failure: true,
        error: action.payload.error,
        amount: action.payload.amount,
        payback: action.payload.payback,
        currency: action.payload.currency
      }
    case RESET_REFUND:
      return {
        ...initialRefundState
      }

    default:
      return state
  }
}

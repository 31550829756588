import React from 'react'
import styled, { css } from 'styled-components'
import { Spinner } from './spinner'
import { Image } from './image'
import paymentcheck from './paymentcheck.svg'
import { tablet } from '../queries/media-queries'

export interface NameProps {
  readonly name: string
}

const baseButtonCss = css`
  background: none;
  border: 0;
  font-family: 'Circular Std';
  color: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0px;
  -webkit-appearance: button;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: relative;

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: inherit;
  }
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
`

export const BaseButton = styled.button.attrs<NameProps>(({ name }) => ({
  type: 'button',
  name: name
}))<NameProps>`
  background: none;
  border: none;
  padding: 0;
  text-decoration: none;
  cursor: pointer;
  font-family: 'Aeonik';
  outline: none;
`

export const ButtonLink = styled(BaseButton)`
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 2.1rem;
  color: var(--color-purple-moona);
`

export const primaryButtonCss = css`
  ${baseButtonCss}
  background: var(--color-purple-moona);
  white-space: nowrap;

  border-radius: 0.8rem;
  font-family: Aeonik;

  font-weight: bold;
  font-size: 1.6rem;
  line-height: 2.4rem;

  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;

  align-self: center;
  margin: 10px 0px;

  &:hover {
    background: var(--color-purple-dark);
  }

  &:focus:before {
    content: '';

    position: absolute;
    display: block;
    z-index: -1;
    top: -3px;
    left: -3px;
    right: -3px;
    bottom: -3px;

    border-radius: 8px;

    color: #ffffff;
    background: var(--color-purple-moona);
    border: 4px solid var(--color-purple-light);
  }

  &:active:before {
    content: '';

    position: absolute;
    display: block;
    z-index: -1;
    top: -3px;
    left: -3px;
    right: -3px;
    bottom: -3px;

    border-radius: 8px;

    color: #ffffff;
    background: var(--color-purple-dark);
    border: 4px solid var(--color-purple-light);
  }

  &:disabled {
    background: rgba(149, 64, 255, 0.5);
    cursor: not-allowed;
  }
`

export const successButtonCss = css`
  ${primaryButtonCss}

  background-color: var(--color-success);
  width: 100%;
  padding: 1.3rem 0;

  &:hover {
    background: var(--color-success-hover);
  }

  &:focus {
    background: var(--color-success);
  }

  &:focus:before {
    background: transparent;
    border: 2px solid var(--color-success-border);
    z-index: 1;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
  }

  &:active {
    background: var(--color-success-hover);
  }
  &:active:before {
    content: '';
    background-color: transparent;
    border: 2px solid var(--color-success-border);
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    z-index: 1;
  }

  &:disabled {
    background: var(--color-success-disable);
  }
`

export const errorButtonCss = css`
  ${primaryButtonCss}

  background-color: var(--color-danger);
  width: 100%;
  padding: 1.3rem 0;

  &:hover {
    background: var(--color-danger-hover);
  }

  &:focus {
    background: var(--color-danger);
  }

  &:focus:before {
    background: transparent;
    border: 2px solid var(--color-danger-border);
    z-index: 1;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
  }

  &:active {
    background: var(--color-danger-hover);
  }
  &:active:before {
    content: '';
    background-color: transparent;
    border: 2px solid var(--color-danger-border);
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    z-index: 1;
  }

  &:disabled {
    background: var(--color-danger-disable);
  }
`
export const cancelButtonCss = css`
  ${primaryButtonCss}

  color: var(--color-black-moona);

  background-color: var(--color-grey-dark);
  width: 100%;
  padding: 1.3rem 0;

  &:hover {
    background: var(--color-grey-link);
  }

  &:focus {
    background: var(--color-grey-dark);
  }

  &:focus:before {
    background: transparent;
    border: 2px solid var(--color-grey-link);
  }

  &:active {
    background: var(--color-grey-link);
  }
  &:active:before {
    border: 2px solid var(--color-grey-link);
  }

  &:disabled {
    background: var(--color-success-disable);
  }
`
interface PrimaryButtonProps extends NameProps {
  px?: string
  py?: string
  success?: string
  type?: 'button' | 'submit' | 'reset' | undefined
}
export const PrimaryButton = styled.button.attrs<NameProps>(
  ({ name, type }) => ({
    type: type || 'button',
    name: name
  })
)<PrimaryButtonProps>`
  ${primaryButtonCss}
  padding: ${props =>
    props.px
      ? props.py
        ? `${props.py}rem ${props.px}rem`
        : `1.2rem ${props.px}rem`
      : '1.2rem 8rem'};
`

export const SuccessButton = styled.button`
  ${successButtonCss}
  display: flex;
`
export const ErrorButton = styled.button`
  ${errorButtonCss}
  display: flex;
`
export const CancelButton = styled.button<{
  onClick?: (e: MouseEvent) => void
}>`
  ${cancelButtonCss}
  display: flex;
`
export const IconSucessButton = styled(({ className, ...props }) => (
  <SuccessButton className={className} {...props}>
    <Image src={paymentcheck} />
  </SuccessButton>
)).attrs({
  target: '_blank',
  rel: 'noopener noreferrer'
})`
  width: ${props => (props.width ? props.width : '16rem')};
  height: 4.8rem;
  @media ${tablet} {
    width: 100%;
  }
`

interface SubmittablePrimaryButtonProps {
  className?: string
  isSubmitting: boolean
  isSubmitted: boolean
  label: string
  name: string
  type?: 'button' | 'submit' | 'reset' | undefined
  disabled?: boolean
  width?: string
}
const BaseSubmittablePrimaryButton: React.FC<SubmittablePrimaryButtonProps> = ({
  className,
  isSubmitting,
  isSubmitted,
  label,
  type = 'button',
  disabled = false,
  width,
  ...props
}) => {
  const body = isSubmitting ? <Spinner /> : label
  if (isSubmitted) {
    return <IconSucessButton width={width} />
  }
  return (
    <PrimaryButton
      className={`${className}`}
      {...props}
      success={'#31DC9E'}
      type={type}
      disabled={disabled}
    >
      {body}
    </PrimaryButton>
  )
}

export const SubmittablePrimaryButton = styled(
  BaseSubmittablePrimaryButton
).attrs(({ type, disabled }) => ({
  target: '_blank',
  rel: 'noopener noreferrer',
  type: type || 'button',
  disabled: disabled || false
}))`
  display: inline-block;
  position: relative;
  display: flex;
  width: 100%;
  height: 4.8rem;
`

import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import { syncViewHeightCssVariable } from 'common/style/view-height'
import React from 'react'
import ReactDOM from 'react-dom'
import { ModalProvider } from 'styled-react-modal'

import Routes from './routes'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import configureStore from './state'
import './i18n/config'
import { GlobalStyle } from './common/style'

import './common/style/css/global-styles.css'
import { ModalBackground } from './common/style/components/modal'

import Amplify from 'aws-amplify'
import awsExports from './aws-exports'

Amplify.configure(awsExports)

const store = configureStore()
syncViewHeightCssVariable()
ReactDOM.render(
  <ModalProvider backgroundComponent={ModalBackground}>
    <Provider store={store}>
      <GlobalStyle />
      <Routes />
    </Provider>
  </ModalProvider>,
  document.getElementById('root')
)

serviceWorker.unregister()

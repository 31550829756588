import { AppActions } from '../types'
import {
  CHANGE_PAYOUTS_TABLE_FILTER,
  ChangePayoutsTableFilter,
  LOAD_PAYOUTS_HEADER_FAILURE,
  LOAD_PAYOUTS_HEADER_REQUEST,
  LOAD_PAYOUTS_HEADER_SUCCESS,
  LOAD_PAYOUTS_TABLE_FAILURE,
  LOAD_PAYOUTS_TABLE_REQUEST,
  LOAD_PAYOUTS_TABLE_SUCCESS,
  LoadPayoutsHeaderFailure,
  LoadPayoutsHeaderRequest,
  LoadPayoutsHeaderSuccess,
  LoadPayoutsTableFailure,
  LoadPayoutsTableRequest,
  LoadPayoutsTableSuccess
} from './types'

export const loadPayoutsHeaderRequest = (
  payload: LoadPayoutsHeaderRequest['payload']
): AppActions => ({
  type: LOAD_PAYOUTS_HEADER_REQUEST,
  payload: {
    stripeId: payload.stripeId
  }
})

export const loadPayoutsHeaderSuccess = ({
  nextPayout,
  lastPayout
}: LoadPayoutsHeaderSuccess['payload']): AppActions => ({
  type: LOAD_PAYOUTS_HEADER_SUCCESS,
  payload: {
    lastPayout,
    nextPayout
  }
})

export const loadPayoutsHeaderFailure = (
  payload: LoadPayoutsHeaderFailure['payload']
): AppActions => ({
  type: LOAD_PAYOUTS_HEADER_FAILURE,
  payload: {
    error: payload.error
  }
})

export const loadPayoutsTableRequest = ({
  stripeId,
  offset,
  limit
}: LoadPayoutsTableRequest['payload']): AppActions => ({
  type: LOAD_PAYOUTS_TABLE_REQUEST,
  payload: {
    stripeId,
    limit,
    offset
  }
})

export const loadPayoutsTableSuccess = ({
  append,
  hasMore,
  rows
}: LoadPayoutsTableSuccess['payload']): AppActions => ({
  type: LOAD_PAYOUTS_TABLE_SUCCESS,
  payload: {
    append,
    hasMore,
    rows
  }
})

export const loadPayoutsTableFailure = (
  payload: LoadPayoutsTableFailure['payload']
): AppActions => ({
  type: LOAD_PAYOUTS_TABLE_FAILURE,
  payload: {
    error: payload.error
  }
})

export const changePayoutsTableFilter = ({
  filter
}: ChangePayoutsTableFilter['payload']): AppActions => ({
  type: CHANGE_PAYOUTS_TABLE_FILTER,
  payload: {
    filter
  }
})

import styled from 'styled-components'
import { extraSmall, tablet } from '../queries/media-queries'
import congratulations from './congratulations.svg'
import login from './login.svg'
import { NameProps } from './buttons'
import { baseFooterTextCss } from './Typography'
export const Container = styled.div`
  width: 100%;
`

export const ColumnContainer = styled(Container)`
  display: flex;
  flex-direction: column;
`
export const RowContainer = styled(Container)`
  display: flex;
`

export const CenterColumnContainer = styled(ColumnContainer)`
  align-items: center;
  justify-content: center;
`

export const FlexContentContainer = styled(Container)`
  font-size: 1.2rem;
  line-height: 2.1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: var(--color-grey-main);
  margin-top: 2rem;
  margin-bottom: 4.4rem;
`
export const DefaultContentContainer = styled(Container)`
  text-align: center;
  @media ${tablet} {
  }
`

export const CenterContentContainer = styled(DefaultContentContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`
export const WideSection = styled.section`
  width: 100%;
`

export const NameableDiv = styled.div.attrs<NameProps>(({ name }) => ({
  name: name
}))<NameProps>``

export const NameableSpan = styled.span.attrs<NameProps>(({ name }) => ({
  name: name
}))<NameProps>``

export const MarginContainer = styled.div<{
  mx?: string
  my?: string
  mt?: string
  mb?: string
  center?: boolean
  selfcenter?: boolean
  column?: boolean
  hflex?: boolean
}>`
  margin-left: ${props => (props.mx ? `${props.mx}` : 0)};
  margin-right: ${props => (props.my ? `${props.my}` : 0)};
  margin-top: ${props => (props.mt ? `${props.mt}` : 0)};
  margin-bottom: ${props => (props.mb ? `${props.mb}` : 0)};
  ${({ hflex }) =>
    hflex &&
    `
  display: flex;
  align-items: center;
  `}

  ${({ center }) =>
    center &&
    `
    display: flex;
    justify-content: center;
    width:100%;

  `}
  ${({ selfcenter }) =>
    selfcenter &&
    `
    align-self: center;
    width:100%;

  `}
  ${({ column }) =>
    column &&
    `
    display: flex;
    flex-direction:column;
  `}
`
export const BorderSection = styled(Section)`
  width: 100%;
  background: #ffffff;
  border: 1px solid #e8e9ed;
  border-radius: 16px;
  padding-top: 0.5rem;
  padding-bottom: 4rem;
  @media ${tablet} {
    padding-top: 4rem;
    height: 24.3rem;
  }
`

export const GreySection = styled(Section)`
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  background: var(--color-grey-light);
  @media ${tablet} {
    height: 24.3rem;
  }
`

export const FooterSection = styled.footer`
  ${baseFooterTextCss}
  text-align:center;
`

export const CongratulationsSection = styled(Section)`
  padding: 2rem;
  @media ${tablet} {
    background-position: top -6rem center;
    background: url(${congratulations});
    background-repeat: no-repeat;
    padding-bottom: 4rem;
    padding-top: 4rem;
  }
`

export const HeroSection = styled(Container)`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: hidden;
  @media ${tablet} {
    overflow-y: auto;
    min-height: 100vh;
  }
`

export const LoginSection = styled(Container)`
  background: url(${login}) var(--color-black);
  background-repeat: no-repeat;
  background-position: calc(50% - 10px) 19.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  @media ${tablet} {
    justify-content: center;
  }
`
export const GreyHeroSection = styled(HeroSection)`
  background: var(--color-grey-light);
`

export const Main = styled.main`
  position: relative;
`
export const CenteredMain = styled(Main)`
  width: 68rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const ContentWrapper = styled(Section)`
  width: 100%;
  @media ${tablet} {
    padding: 0 2rem;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 3.8rem 5.6rem;
    justify-content: center;
  }
`

export const Kpi = styled(Section)`
  padding: 3.2rem 3.2rem 4rem 3.2rem;
  width: 100%;
  background: #ffffff;
  border-radius: 16px;
  height: 21.2rem;
  border: 1px solid var(--color-grey-light);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
`
export const Row = styled(Container)`
  display: flex;
  flex-direction: column;
  @media ${tablet} {
    flex-direction: row;
    align-items: center;
  }
`

export const KpiRow = styled(Row)`
  flex-direction: row;
  align-items: center;
`
export const InputWrapper = styled(Container)``

export const SearchAndPaginateWrapper = styled(Container)`
  padding: 1.7rem 1rem 1.7rem 2.5rem;
  background: #ffffff;
  box-shadow: 0px 1px 0px var(--color-grey-light);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border: 1px solid var(--color-grey-light);
  @media ${extraSmall} {
    padding: 1.7rem 0rem 1.7rem 0rem;
  }
`
export const SearchAndPaginateLayout = styled(Section)`
  flex-direction: row;
  justify-content: space-between;
`

import { useEffect, useState } from 'react'

export enum DeviceType {
  tablet = 'tablet',
  mobile = 'mobile'
}

const getDeviceType = (windowWidth: number) => {
  if (windowWidth < 1368) return DeviceType.mobile
  return DeviceType.tablet
}

export const useDeviceType = () => {
  const [deviceType, setDeviceType] = useState(getDeviceType(window.innerWidth))

  useEffect(() => {
    const onResize = () => setDeviceType(getDeviceType(window.innerWidth))

    window.addEventListener('resize', onResize)

    return () => window.removeEventListener('resize', onResize)
  })

  return deviceType
}

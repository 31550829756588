export const CREATE_PAYOUT_REQUEST = 'CREATE_PAYOUT_REQUEST'
export const CREATE_PAYOUT_SUCCESS = 'CREATE_PAYOUT_SUCCESS'
export const CREATE_PAYOUT_FAILURE = 'CREATE_PAYOUT_FAILURE'
export const CREATE_PAYOUT_ERROR = 'CREATE_PAYOUT_ERROR'
export const LOAD_PAYOUT_REQUEST = 'LOAD_PAYOUT_REQUEST'
export const LOAD_PAYOUT_SUCCESS = 'LOAD_PAYOUT_SUCCESS'
export const LOAD_PAYOUT_FAILURE = 'LOAD_PAYOUT_FAILURE'
export const LOAD_PAYOUT_STATUS = 'LOAD_PAYOUT_STATUS'
export const SET_PAYOUT_STATUS = 'SET_PAYOUT_STATUS'

export const RESET_PAYOUT = 'RESET_PAYOUT'

export interface CreatePayoutRequest {
  type: typeof CREATE_PAYOUT_REQUEST
  payload: { stripeId: string }
}
export interface CreatePayoutSuccess {
  type: typeof CREATE_PAYOUT_SUCCESS
}
export interface CreatePayoutFailure {
  type: typeof CREATE_PAYOUT_FAILURE
  payload: {
    error: string
  }
}
export interface LoadPayoutRequest {
  type: typeof LOAD_PAYOUT_REQUEST
  payload: { stripeId: string }
}
export interface LoadPayoutSuccess {
  type: typeof LOAD_PAYOUT_SUCCESS
  payload: { amount: number }
}
export interface LoadPayoutFailure {
  type: typeof LOAD_PAYOUT_FAILURE
  payload: {
    error: string
  }
}

export interface CreatePayoutError {
  type: typeof CREATE_PAYOUT_ERROR
  payload: {
    error: string
  }
}
export interface ResetPayout {
  type: typeof RESET_PAYOUT
}
export interface LoadPayoutStatus {
  type: typeof LOAD_PAYOUT_STATUS

  payload: { stripeId: string }
}
export interface SetPayoutStatus {
  type: typeof SET_PAYOUT_STATUS
  payload: {
    payoutsEnabled: boolean
  }
}

export interface PayoutState {
  loading: boolean
  success: boolean
  failure: boolean
  loadLoading: boolean
  loadSuccess: boolean
  loadFailure: boolean
  error: string
  amount: number
  previousAmount: number
  payoutsEnabled: boolean
  payoutsEnabledLoading: boolean
}

export type PayoutActionTypes =
  | CreatePayoutRequest
  | CreatePayoutError
  | CreatePayoutSuccess
  | LoadPayoutRequest
  | LoadPayoutFailure
  | LoadPayoutSuccess
  | CreatePayoutFailure
  | ResetPayout
  | LoadPayoutStatus
  | SetPayoutStatus

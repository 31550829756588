import {
  LOAD_TRANSACTIONS_REQUEST,
  LOAD_TRANSACTIONS_ERROR,
  LOAD_TRANSACTIONS_SUCCESS,
  LOAD_TRANSACTIONS_FAILURE,
  RESET_TRANSACTIONS
} from './types'

import { AppActions } from '../types'

export const createTransactionsRequest = (stripeId: string): AppActions => ({
  type: LOAD_TRANSACTIONS_REQUEST,
  payload: { stripeId: stripeId }
})
export const createTransactionsSuccess = ({
  total,
  moonaFunding
}: {
  total: number
  moonaFunding: number
}): AppActions => ({
  type: LOAD_TRANSACTIONS_SUCCESS,
  payload: {
    total,
    moonaFunding
  }
})
export const createTransactionsFailure = ({
  error
}: {
  error: string
}): AppActions => ({
  type: LOAD_TRANSACTIONS_FAILURE,
  payload: {
    error
  }
})

export const createTransactionsError = ({
  error
}: {
  error: string
}): AppActions => ({
  type: LOAD_TRANSACTIONS_ERROR,
  payload: {
    error
  }
})
export const resetTransactions = (): AppActions => ({
  type: RESET_TRANSACTIONS
})

export const LOAD_DATA_REQUEST = 'LOAD_DATA_REQUEST'

export const LOAD_DATA_SUCCESS = 'LOAD_DATA_SUCCESS'
export const LOAD_DATA_FAILURE = 'LOAD_DATA_FAILURE'
export const LOAD_DATA_ERROR = 'LOAD_DATA_ERROR'
export const RESET_DATA = 'RESET_DATA'

export const SET_SEARCH_EMAIL = 'SET_SEARCH_EMAIL'
export const CLEAR_SEARCH_EMAIL = 'CLEAR_SEARCH_EMAIL'

export interface ServerTransactionRow {
  orderId: string
  customerEmail: string
  amount: number
  datePayment: number
  moonaFunding: number
  amountReversed: number
  isLastPartialRefund: boolean
  reversed: boolean
  type: 'refund' | 'moona-discount'
  currency: string
  transactions: {
    transferId: string
    transferMoonaId: string
    transferShippingId: string
  }
}

export interface TransactionRow {
  id: string
  customer: string
  total: number
  amount: number
  payback: number
  date: number
  isLastPartialRefund: boolean
  refund: 'Refunded' | ''
  currency: string
  transactions: {
    transferId: string
    transferMoonaId: string
    transferShippingId: string
  }
  amountReversed: number
  reversed: boolean
  type: 'refund' | 'moona-discount'
}

export interface LoadDataRequest {
  type: typeof LOAD_DATA_REQUEST
  payload: { stripeId: string; limit?: number; offset?: string; email?: string }
}
export interface LoadDataSuccess {
  type: typeof LOAD_DATA_SUCCESS
  payload: {
    data: TransactionRow[]
    hasMore: boolean
    append?: boolean
  }
}
export interface LoadDataFailure {
  type: typeof LOAD_DATA_FAILURE
  payload: {
    error: string
  }
}

export interface LoadDataError {
  type: typeof LOAD_DATA_ERROR
  payload: {
    error: string
  }
}
export interface ResetTable {
  type: typeof RESET_DATA
}

export interface SetSearchEmail {
  type: typeof SET_SEARCH_EMAIL
  payload: {
    value: string
  }
}

export interface ClearSearchEmail {
  type: typeof CLEAR_SEARCH_EMAIL
}

export interface TableState {
  data: TransactionRow[]
  searchEmail: string
  hasMore: boolean
  pageIndex: number
  loading: boolean
  success: boolean
  failure: boolean
  error: string
}

export type TableActionTypes =
  | LoadDataRequest
  | LoadDataError
  | LoadDataSuccess
  | LoadDataFailure
  | ResetTable
  | SetSearchEmail
  | ClearSearchEmail

import {
  PayoutState,
  PayoutActionTypes,
  CREATE_PAYOUT_ERROR,
  CREATE_PAYOUT_REQUEST,
  CREATE_PAYOUT_SUCCESS,
  CREATE_PAYOUT_FAILURE,
  LOAD_PAYOUT_REQUEST,
  LOAD_PAYOUT_SUCCESS,
  LOAD_PAYOUT_FAILURE,
  SET_PAYOUT_STATUS,
  RESET_PAYOUT,
  LOAD_PAYOUT_STATUS
} from './types'

export const initialPayoutState: PayoutState = {
  loading: false,
  success: false,
  failure: false,
  payoutsEnabled: false,
  loadLoading: false,
  loadSuccess: false,
  loadFailure: false,
  payoutsEnabledLoading: true,
  error: '',
  amount: 0,
  previousAmount: 0
}

export default function payout(
  state = initialPayoutState,
  action: PayoutActionTypes
): PayoutState {
  switch (action.type) {
    case CREATE_PAYOUT_REQUEST:
      return {
        ...state,
        loading: true,
        previousAmount: state.amount
      }
    case CREATE_PAYOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        failure: false,
        error: ''
      }
    case CREATE_PAYOUT_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        failure: true,
        error: action.payload.error
      }
    case LOAD_PAYOUT_REQUEST:
      return {
        ...state,
        loadLoading: true
      }
    case LOAD_PAYOUT_SUCCESS:
      return {
        ...state,
        loadLoading: false,
        loadSuccess: true,
        loadFailure: false,
        amount: action.payload.amount,
        error: ''
      }
    case LOAD_PAYOUT_FAILURE:
      return {
        ...state,
        loadLoading: false,
        loadSuccess: false,
        loadFailure: true,
        error: action.payload.error
      }

    case CREATE_PAYOUT_ERROR:
      return {
        ...state,
        error: action.payload.error
      }
    case RESET_PAYOUT:
      return {
        ...initialPayoutState
      }
    case SET_PAYOUT_STATUS:
      return {
        ...state,
        payoutsEnabled: action.payload.payoutsEnabled,
        payoutsEnabledLoading: false
      }
    case LOAD_PAYOUT_STATUS:
      return {
        ...state,
        payoutsEnabledLoading: true
      }

    default:
      return state
  }
}

import { takeLatest, call, fork, put } from 'redux-saga/effects'

import { API, graphqlOperation, Auth } from 'aws-amplify'

import {
  setPwdSuccess,
  userError,
  setUserMailAndId,
  createUserFailure,
  loadUser,
  fetchAccountLink,
  fetchAccountLinkSuccess,
  setKeys
} from './actions'

import {
  SET_FIRST_PWD,
  UPDATE_PWD,
  LOAD_USER_ACCOUNT_LINK,
  CREATE_USER_REQUEST,
  FINALIZE_REGISTRATION,
  SetFirstPwdAction,
  UpdatePwdAction,
  FinalizeRegistration,
  LoadUserLinkAction,
  CreateUserRequestAction,
  LOAD_KEYS,
  LoadKeys
} from './types'
import {
  createMoonaMerchant,
  finaliseMerchantRegistration,
  getStripeDashboardLink,
  keysByMerchantId,
  setUserFirstPassword
} from 'graphql/queries'
import { redirectToStripe } from './api'
import { check } from './utils'
import { loadPayoutStatus } from '../payout/actions'
import { setFinalizeRegistrationStatus } from '../auth/actions'

function* finalizeRegistration(action: FinalizeRegistration) {
  try {
    const { state, code } = action.payload
    const params = { id: state, token: code }

    const finaliseMerchantResult = yield call(
      [API, 'graphql'],
      graphqlOperation(finaliseMerchantRegistration, params)
    )

    const merchantResult = check(
      finaliseMerchantResult,
      'finaliseMerchantRegistration'
    )

    yield put(setFinalizeRegistrationStatus({ status: 'done' }))

    window.history.replaceState(
      null,
      window.document.title,
      window.location.pathname
    )

    yield put(
      loadUser({
        firstName: merchantResult.body.merchant.firstname,
        lastName: merchantResult.body.merchant.lastname,
        email: merchantResult.body.merchant.email,
        phone: merchantResult.body.merchant.phone,
        stripeId: merchantResult.body.merchant.stripeId,
        id: merchantResult.body.merchant.id,
        businessUrl: merchantResult.body.merchant.website,
        businessDescription: merchantResult.body.merchant.businessDescription,
        companyType: merchantResult.body.merchant.companyType
      })
    )

    const { stripeId } = merchantResult.body.merchant

    yield put(loadPayoutStatus(stripeId))

    yield put(fetchAccountLink(stripeId))

    // yield put(loadPayoutRequest(stripeId))
    // yield put(loadTableRequest(stripeId))
    // yield put(createTransactionsRequest(stripeId))
  } catch (e) {
    console.log(e)
    window.location.href = `${window.location.origin}/login`
  }
}

function* watchFinalizeRegistration() {
  yield takeLatest(FINALIZE_REGISTRATION, finalizeRegistration)
}

function* createMerchantAction(action: CreateUserRequestAction) {
  try {
    const { userSub }: { userSub: string } = yield call([Auth, 'signUp'], {
      username: action.payload.email.toLowerCase(),
      password: action.payload.password,
      clientMetadata: {
        group: 'merchant'
      }
    })

    yield put(
      setUserMailAndId({ email: action.payload.email.toLowerCase(), moonaId: userSub })
    )

    const {
      email,
      firstname,
      lastname,

      phone,
      businessUrl,
      businessDescription,
      companyType,
      annualTurnover,
      ecommerceSolution
    } = action.payload

    const result = yield call(
      [API, 'graphql'],
      graphqlOperation(createMoonaMerchant, {
        email: email.toLowerCase(),
        firstname,
        lastname,
        phone,
        website: businessUrl,
        businessUrl,
        businessDescription,
        companyType,
        annualTurnover,
        ecommerceSolution,
        token: userSub
      })
    )

    const moonaMerchantData = check(result, 'createMoonaMerchant')
    const newMerchant = moonaMerchantData.body.merchant

    redirectToStripe({
      id: newMerchant.id,
      email: newMerchant.email,
      firstName: newMerchant.firstname,
      lastName: newMerchant.lastname,
      businessUrl: newMerchant.website,
      phone: newMerchant.phone,
      businessDescription: newMerchant.businessDescription,
      companyType: newMerchant.companyType
    })
  } catch (error) {
    console.log('error catché: ', error)
    let msg = ''
    switch (error.code) {
      case 'UsernameExistsException':
        msg = 'Email already registered'
        break
      case 'InvalidParameterException':
        msg = 'Email and password does not match'
        break

      default:
        msg = 'ERROR'
        break
    }
    if (error.moonaId) {
      console.log('catch error with moonaid, deleting user')
    }
    yield put(createUserFailure({ error: msg }))
  }
}

function* watchCreateUserRequest() {
  yield takeLatest(CREATE_USER_REQUEST, createMerchantAction)
}

function* loadKeysAction(action: LoadKeys) {
  try {
    const result = yield call(
      [API, 'graphql'],
      graphqlOperation(keysByMerchantId, {
        merchantId: action.payload.id
      })
    )
    const {
      publishableKeyLive,
      publishableKeyTest,
      secretKeyLive,
      secretKeyTest
    } = result.data.keysByMerchantId.items[0]

    yield put(
      setKeys({
        publishableKeyLive,
        publishableKeyTest,
        secretKeyLive,
        secretKeyTest
      })
    )
    // yield put(setPwdSuccess({ password: action.payload.password }))
  } catch (e) {
    console.log(e)
  }
}
function* watchLoadKeysRequest() {
  yield takeLatest(LOAD_KEYS, loadKeysAction)
}

function* setFirstPwd(action: SetFirstPwdAction) {
  const { email, password } = action.payload
  try {
    const result = yield call(
      [API, 'graphql'],
      graphqlOperation(setUserFirstPassword, {
        email: email.toLowerCase(),
        newPassword: password
      })
    )
    check(result, 'setUserFirstPassword')
    yield put(setPwdSuccess({ password: action.payload.password }))
  } catch (e) {
    console.log('an error occured when try to set the password', e)
    let msg = ''
    switch (e.code) {
      case 'ERROR-MOONA-EXPIRED-LINK':
        msg =
          'An error occurred when trying to set your password: ERROR-MOONA-EXPIRED-LINK'
        break
    }
    yield put(
      userError({
        error: msg || 'An error occurred when trying to set your password'
      })
    )
  }
}

function* watchSetFirstPwdRequest() {
  yield takeLatest(SET_FIRST_PWD, setFirstPwd)
}

function* loadAccountLink(action: LoadUserLinkAction) {
  const { stripeId } = action.payload

  try {
    const result = yield call(
      [API, 'graphql'],
      graphqlOperation(getStripeDashboardLink, {
        stripeUserId: stripeId
      })
    )

    const linkData = check(result, 'getStripeDashboardLink')

    yield put(fetchAccountLinkSuccess({ accountLink: linkData.body.url }))
  } catch (e) {
    console.log('loadAccountLink error', e.code, e.message)

    yield put(
      userError({
        error: 'An error occured while loading account link'
      })
    )
  }
}

function* watchFetchAccountLinkRequest() {
  yield takeLatest(LOAD_USER_ACCOUNT_LINK, loadAccountLink)
}

function* updatePwd(action: UpdatePwdAction) {
  const { username, code, password } = action.payload
  try {
    yield call([Auth, 'forgotPasswordSubmit'], username.toLowerCase(), code, password)

    yield put(setPwdSuccess({ password: action.payload.password }))
  } catch (e) {
    console.log('an error occured when try to update the password', e)
    yield put(
      userError({
        error: 'An error occurred when trying to update your password'
      })
    )
  }
}

function* watchUpdatePwdRequest() {
  yield takeLatest(UPDATE_PWD, updatePwd)
}

const usersSagas = [
  fork(watchSetFirstPwdRequest),
  fork(watchUpdatePwdRequest),
  fork(watchCreateUserRequest),
  fork(watchFinalizeRegistration),
  fork(watchFetchAccountLinkRequest),
  fork(watchLoadKeysRequest)
]
export default usersSagas

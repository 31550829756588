import {
  CHANGE_PAYOUTS_TABLE_FILTER,
  LOAD_PAYOUTS_HEADER_FAILURE,
  LOAD_PAYOUTS_HEADER_REQUEST,
  LOAD_PAYOUTS_HEADER_SUCCESS,
  LOAD_PAYOUTS_TABLE_FAILURE,
  LOAD_PAYOUTS_TABLE_REQUEST,
  LOAD_PAYOUTS_TABLE_SUCCESS,
  PayoutsActionTypes,
  PayoutsState
} from './types'

export const initialPayoutsState: PayoutsState = {
  header: {
    loading: false,
    success: false,
    error: null,
    lastPayout: null,
    nextPayout: null
  },
  table: {
    filter: '',
    loading: false,
    success: false,
    error: null,
    hasMore: false,
    pageIndex: 0,
    rows: []
  }
}

export default function payouts(
  state = initialPayoutsState,
  action: PayoutsActionTypes
): PayoutsState {
  switch (action.type) {
    case LOAD_PAYOUTS_HEADER_SUCCESS:
      return {
        ...state,
        header: {
          ...state.header,
          nextPayout: action.payload.nextPayout,
          lastPayout: action.payload.lastPayout,
          loading: false,
          success: true,
          error: null
        }
      }
    case LOAD_PAYOUTS_HEADER_FAILURE:
      return {
        ...state,
        header: {
          ...state.header,
          loading: false,
          success: false,
          error: action.payload.error
        }
      }
    case LOAD_PAYOUTS_HEADER_REQUEST:
      return {
        ...state,
        header: {
          ...state.header,
          success: false,
          error: null,
          loading: true
        }
      }
    case LOAD_PAYOUTS_TABLE_SUCCESS:
      return {
        ...state,
        table: {
          ...state.table,
          ...(action.payload.append
            ? {
                rows: [...state.table.rows, ...action.payload.rows]
              }
            : { rows: action.payload.rows }),
          pageIndex: action.payload.append ? state.table.pageIndex + 1 : 0,
          hasMore: action.payload.hasMore,
          loading: false,
          success: true,
          error: null
        }
      }
    case LOAD_PAYOUTS_TABLE_FAILURE:
      return {
        ...state,
        table: {
          ...state.table,
          loading: false,
          success: false,
          error: action.payload.error
        }
      }
    case LOAD_PAYOUTS_TABLE_REQUEST:
      return {
        ...state,
        table: {
          ...state.table,
          success: false,
          error: null,
          loading: true
        }
      }
    case CHANGE_PAYOUTS_TABLE_FILTER:
      return {
        ...state,
        table: {
          ...state.table,
          filter: action.payload.filter
        }
      }
    default:
      return state
  }
}

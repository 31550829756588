import { call, put, takeEvery, fork } from 'redux-saga/effects'

import {
  CREATE_PAYOUT_REQUEST,
  LOAD_PAYOUT_REQUEST,
  LoadPayoutRequest,
  CreatePayoutRequest,
  LOAD_PAYOUT_STATUS,
  LoadPayoutStatus
} from './types'
import {
  createPayoutSuccess,
  createPayoutFailure,
  loadPayoutSuccess,
  loadPayoutFailure,
  loadPayoutRequest,
  setPayoutStatus
} from './actions'
import { API, graphqlOperation } from 'aws-amplify'
import { check } from '../users/utils'
import {
  getMerchantBalance,
  getMerchantInformation,
  payoutNow
} from 'graphql/queries'

function* payout(action: CreatePayoutRequest) {
  try {
    const result = yield call(
      [API, 'graphql'],
      graphqlOperation(payoutNow, { stripeUserId: action.payload.stripeId })
    )

    check(result, 'payoutNow')

    yield put(loadPayoutRequest(action.payload.stripeId))
    yield put(createPayoutSuccess())
  } catch (error) {
    console.log('error with payout', error)
    yield put(createPayoutFailure({ error: 'error from stripe' }))
  }

  /* Simulate success */
  // yield put(createPayoutSuccess())

  /* Simulate error */
  // yield put(createPayoutFailure({ error: 'error from stripe' }))
}

function* watchPayout() {
  yield takeEvery(CREATE_PAYOUT_REQUEST, payout)
}

function* loadPayout(action: LoadPayoutRequest) {
  try {
    const payoutData = yield call(
      [API, 'graphql'],
      graphqlOperation(getMerchantBalance, {
        stripeUserId: action.payload.stripeId
      })
    )

    const payout = check(payoutData, 'getMerchantBalance')

    yield put(loadPayoutSuccess(payout.body.amount / 100))
  } catch (error) {
    console.log('loadPayout error', error.code, error.message)
    yield put(loadPayoutFailure({ error: 'error from stripe' }))
  }
}

function* watchLoadPayout() {
  yield takeEvery(LOAD_PAYOUT_REQUEST, loadPayout)
}

function* loadPayoutStatusAction(action: LoadPayoutStatus) {
  if (!action.payload.stripeId) {
    return
  }
  try {
    const merchantInformationResult = yield call(
      [API, 'graphql'],
      graphqlOperation(getMerchantInformation, {
        stripeUserId: action.payload.stripeId
      })
    )

    const merchantInformationData = check(
      merchantInformationResult,
      'getMerchantInformation'
    )

    yield put(
      setPayoutStatus(merchantInformationData.body.merchant.payouts_enabled)
    )
  } catch (error) {
    console.log('loadPayoutStatus error', error.code, error.message)
    yield put(loadPayoutFailure({ error: 'error from stripe' }))
  }
}

function* watchLoadPayoutStatus() {
  yield takeEvery(LOAD_PAYOUT_STATUS, loadPayoutStatusAction)
}

const payoutSagas = [
  fork(watchPayout),
  fork(watchLoadPayout),
  fork(watchLoadPayoutStatus)
]
export default payoutSagas

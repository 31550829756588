export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE'
export const LOAD_USER = 'LOAD_USER'
export const LOAD_USER_ACCOUNT_LINK = 'LOAD_USER_ACCOUNT_LINK'
export const LOAD_USER_ACCOUNT_LINK_SUCCESS = 'LOAD_USER_ACCOUNT_LINK_SUCCESS'
export const CLEAR_USER = 'CLEAR_USER'

export const USER_ERROR = 'USER_ERROR'
export const SET_PWD = 'SET_PWD'
export const SET_PWD_SUCCESS = 'SET_PWD_SUCCESS'
export const GET_MAIL = 'GET_MAIL'
export const GET_MAIL_SUCCESS = 'GET_MAIL_SUCCESS'
export const CREATE_MOONA_USER = 'CREATE_MOONA_USER'
export const SET_EMAIL_MOONAID_SECRET = 'SET_EMAIL_MOONAID_SECRET'
export const FINALIZE_REGISTRATION = 'FINALIZE_REGISTRATION'
export const UPDATE_PWD = 'UPDATE_PWD'
export const SET_FIRST_PWD = 'SET_FIRST_PWD'
export const LOAD_KEYS = 'LOAD_KEYS'
export const SET_KEYS = 'SET_KEYS'

export type KEYS = {
  publishableKeyLive: string
  publishableKeyTest: string
  secretKeyLive: string
  secretKeyTest: string
}
export interface User {
  id: string
  email: string
  phone?: string
  firstName: string
  lastName?: string
  password?: string
  stripeId?: string
  businessUrl: string
  businessDescription?: string
  companyType?: string
  status?: string
  annualTurnover?: string
  ecommerceSolution?: string
  accountLink?: string
  keys?: KEYS
}

export interface CreateUserRequestAction {
  type: typeof CREATE_USER_REQUEST
  payload: {
    businessUrl: string
    email: string
    firstname: string
    lastname: string
    country: string
    phone: string
    password: string
    businessDescription: string
    companyType: string
    annualTurnover: string
    ecommerceSolution: string
  }
}

export interface CreateUserSuccess {
  type: typeof CREATE_USER_SUCCESS
}
export interface CreateUserFailure {
  type: typeof CREATE_USER_FAILURE
  payload: {
    error: string
  }
}
export interface LoadUser {
  type: typeof LOAD_USER
  payload: User
}
export interface ClearUser {
  type: typeof CLEAR_USER
}

export interface CreateMoonaUserRequestAction {
  type: typeof CREATE_MOONA_USER
}

export interface SetPwdAction {
  type: typeof SET_PWD
  payload: {
    email: string
    password: string
  }
}
export interface SetPwdSuccessAction {
  type: typeof SET_PWD_SUCCESS
  payload: {
    password: string
  }
}

export interface GetMailAction {
  type: typeof GET_MAIL
  payload: {
    id: string
  }
}
export interface GetMailSuccessAction {
  type: typeof GET_MAIL_SUCCESS
  payload: {
    email: string
  }
}

export interface UserErrorAction {
  type: typeof USER_ERROR
  payload: {
    error: string
  }
}

export interface SetUserMailAndId {
  type: typeof SET_EMAIL_MOONAID_SECRET
  payload: {
    email: string
    moonaId: string
  }
}
export interface FinalizeRegistration {
  type: typeof FINALIZE_REGISTRATION
  payload: {
    code: string
    state: string
  }
}

export interface SetFirstPwdAction {
  type: typeof SET_FIRST_PWD
  payload: {
    email: string
    password: string
  }
}
export interface UpdatePwdAction {
  type: typeof UPDATE_PWD
  payload: {
    username: string
    code: string
    password: string
  }
}

export interface LoadUserLinkAction {
  type: typeof LOAD_USER_ACCOUNT_LINK
  payload: {
    stripeId: string
  }
}
export interface LoadUserLinkSuccess {
  type: typeof LOAD_USER_ACCOUNT_LINK_SUCCESS
  payload: {
    accountLink: string
  }
}

export interface LoadKeys {
  type: typeof LOAD_KEYS
  payload: {
    id: string
  }
}

export interface SetKeys {
  type: typeof SET_KEYS
  payload: {
    keys: KEYS
  }
}

export type UsersActionTypes =
  | SetFirstPwdAction
  | UpdatePwdAction
  | SetPwdSuccessAction
  | GetMailAction
  | GetMailSuccessAction
  | UserErrorAction
  | CreateUserRequestAction
  | CreateUserSuccess
  | CreateUserFailure
  | CreateMoonaUserRequestAction
  | LoadUser
  | ClearUser
  | SetUserMailAndId
  | FinalizeRegistration
  | LoadUserLinkAction
  | LoadUserLinkSuccess
  | LoadKeys
  | SetKeys

export interface UsersState extends User {
  loading: boolean
  passwordUpdated: boolean
  userloaded: boolean
  error: string
  success: boolean
  failure: boolean
}

import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { NameProps } from './buttons'

const baseLinkCss = css`
  display: flex;
  justify-content: center;
  align-items: center;

  white-space: nowrap;
  text-decoration: none;
  /* color: var(--color-purple-moona); */

  font-weight: 500;

  color: var(--color-blackmain-moona);
`

export const ExternalLink = styled.a.attrs<NameProps>(({ name }) => ({
  target: '_blank',
  rel: 'noopener noreferrer',
  name: name
}))<NameProps>`
  ${baseLinkCss}
  display: inline-block;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: var(--color-blackmain-moona);
`

export const InternalLink = styled(Link).attrs<NameProps>(({ name }) => ({
  name: name
}))<NameProps>`
  text-decoration: none;
  color: inherit;
`

export const InternalBaseLink = styled(InternalLink)`
  ${baseLinkCss}
`

export const InlineLink = styled(InternalBaseLink)`
  display: inline-block;
  margin-left: 0.5rem;
`

const BaseNewTabLink: React.FC<{ to: string; name: string }> = ({
  to,
  name,

  ...props
}) => {
  return (
    <InternalBaseLink
      name={name}
      {...props}
      to={`${process.env.PUBLIC_URL}${to}`}
    />
  )
}

export const NewTabLink = styled(BaseNewTabLink).attrs(({ name }) => ({
  target: '_blank',
  rel: 'noopener noreferrer',
  name: name
}))<NameProps>`
  display: inline-block;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: var(--color-blackmain-moona);
`
